import { Injectable } from '@angular/core';
import { MenuConfig, MenuOption } from './menu/menu.model';
import { WashListComponent } from './wash-list/wash-list.component';
import { AuthService } from '../core/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { DataStore } from './dashboard.model';
import { APIService } from '../core/API.service';
import { HomeComponent } from './home/home.component';
import { GtmUtilsService } from '../shared/gtm-utils.service';

@Injectable()
export class DashboardService {
  private _breakpointFlag = new BehaviorSubject(true);
  currentBreakpoint = this._breakpointFlag.asObservable();
  shouldDisplaySchneiderCompletionOnMenu: boolean = false;

  constructor(
    private authService: AuthService,
    private api: APIService,
    private gtmUtilsService: GtmUtilsService
  ) {}

  public dataStore: DataStore = {
    businessPartners: [],
    businessPartnersNCR: [],
  };

  getMenuConfig(): MenuConfig {
    const menu = new MenuConfig();

    const hasAllReportActions: boolean = this.authService.hasAllReportActions();

    let currentRole: string;

    if (this.authService.user && this.authService.user.currentRole) {
      currentRole = this.authService.user.currentRole;

      // Add Home section
      menu.sections.push({
        label: 'Home',
        icon: 'home',
        value: HomeComponent.route,
        options: [{ label: 'Home', icon: 'home', value: MenuOption.Home }],
      });
    }

    if (currentRole.includes('-Manager')) {
      // Add Wash List section

      const options = [
        { label: 'Drafts', icon: 'edit', value: MenuOption.Draft },
        {
          label: 'New Requests',
          icon: 'plus-circle',
          value: MenuOption.NewWashRequests,
        },
        {
          label: 'Pending',
          icon: 'hourglass-half',
          value: MenuOption.Pending,
        },
        { label: 'Accepted', icon: 'star', value: MenuOption.Accepted },
        {
          label: 'Credit Hold',
          icon: 'credit-card',
          value: MenuOption.CreditHold,
        },
        {
          label: 'Needs Action',
          icon: 'exclamation-circle',
          value: MenuOption.NeedsAction,
        },
        {
          label: 'In Progress',
          icon: 'sync-alt',
          value: MenuOption.InProgress,
        },
        { label: 'Hold', icon: 'hand-paper', value: MenuOption.Hold },
        {
          label: 'Completed',
          icon: 'check-circle',
          value: MenuOption.Completed,
        },
        { label: 'Canceled', icon: 'ban', value: MenuOption.Canceled },
        {
          label: 'Rejected',
          icon: 'times-circle',
          value: MenuOption.Rejected,
        },
        {
          label: 'Expired Requests',
          icon: 'hourglass-end',
          value: MenuOption.Expired,
        },
      ];

      menu.sections.push({
        label: 'Request List',
        icon: 'list-ul',
        value: WashListComponent.route,
        options: options,
      });

      this.gtmUtilsService
        .getGtmConfig('showSchneiderPortalCompletions')
        .then((value) => {
          if (value) {
            options.push({
              label: 'Schneider Portal Completions',
              icon: 'truck',
              value: MenuOption.SchneiderPortalCompletions,
            });
          }
        });

      // Add Schedule section
      const isTerminalActive = this.authService.user.currentTerminal.active;
      if (isTerminalActive) {
        menu.sections.push({
          label: 'Schedule',
          icon: 'calendar',
          value: 'schedule',
          options: [
            { label: 'Schedule', icon: 'calendar', value: MenuOption.Schedule },
          ],
        });
      }

      const settingsOptions = [
        { label: 'Bay', icon: 'truck', value: MenuOption.BaySettings },
        {
          label: 'Hours of Operation',
          icon: 'clock',
          value: MenuOption.HoursOfOperation,
        },
        {
          label: 'Velocity',
          icon: 'tachometer-alt',
          value: MenuOption.VelocitySettings,
        },
      ];

      const activatableTerminal = 'wash';
      const terminalBeActivated =
        this.authService.user.currentTerminal.linesOfBusiness.some(
          (lineOfBusiness) =>
            lineOfBusiness.toLowerCase().includes(activatableTerminal)
        );

      if (!isTerminalActive && terminalBeActivated) {
        settingsOptions.push({
          label: 'Activate Terminal',
          icon: 'check-circle',
          value: MenuOption.ActivateTerminal,
        });
      }

      if (terminalBeActivated) {
        // Add settings section
        menu.sections.push({
          label: 'Settings',
          icon: 'cog',
          value: 'settings',
          options: settingsOptions,
        });
      }

      // Add Reports
      menu.sections.push({
        icon: 'chart-line',
        label: 'Reports',
        value: 'reports',
        options: [
          {
            icon: 'chart-line',
            label: 'Lean Daily Management',
            value: MenuOption.LeanDailyManagementReport,
          },
          {
            icon: 'chart-line',
            label: 'Terminal Reports',
            value: MenuOption.Reports,
          },
          {
            icon: 'soap',
            label: 'Terminal Scorecard - Tank Wash & IBC',
            value: MenuOption.TerminalScorecardWash,
          },
          {
            icon: 'truck',
            label: 'Terminal Scorecard - Depot & Transportation',
            value: MenuOption.TerminalScorecardDepot,
          },
          {
            icon: 'industry',
            label: 'Terminal Scorecard - Industrial Services',
            value: MenuOption.TerminalScorecardIndustrial,
          },
          {
            icon: 'train',
            label: 'Terminal Scorecard - Rail & Specialty',
            value: MenuOption.TerminalScorecardRail,
          },
          {
            icon: 'recycle',
            label: 'Waste Management',
            value: MenuOption.EhsWasteManagement,
          },
          {
            icon: 'external-link',
            label: 'Reports Portal',
            value: MenuOption.ReportsPortal,
          },
        ],
      });

      // Add Shift Logs
      menu.sections.push({
        icon: 'table',
        label: 'Shift Logs',
        value: 'shift-logs',
        options: [
          {
            icon: 'table',
            label: 'Terminal Shift Logs',
            value: MenuOption.ShiftLogs,
          },
        ],
      });
    } else if (
      currentRole.includes('CSC') ||
      currentRole.includes('Ticket') ||
      currentRole.includes('Cleaner')
    ) {
      const options = [
        { label: 'Drafts', icon: 'edit', value: MenuOption.Draft },
        {
          label: 'New Requests',
          icon: 'plus-circle',
          value: MenuOption.NewWashRequests,
        },
        {
          label: 'Pending',
          icon: 'hourglass-half',
          value: MenuOption.Pending,
        },
        { label: 'Accepted', icon: 'star', value: MenuOption.Accepted },
        {
          label: 'Credit Hold',
          icon: 'credit-card',
          value: MenuOption.CreditHold,
        },
        {
          label: 'Needs Action',
          icon: 'exclamation-circle',
          value: MenuOption.NeedsAction,
        },
        {
          label: 'In Progress',
          icon: 'sync-alt',
          value: MenuOption.InProgress,
        },
        { label: 'Hold', icon: 'hand-paper', value: MenuOption.Hold },
        {
          label: 'Completed',
          icon: 'check-circle',
          value: MenuOption.Completed,
        },
        { label: 'Canceled', icon: 'ban', value: MenuOption.Canceled },
        {
          label: 'Rejected',
          icon: 'times-circle',
          value: MenuOption.Rejected,
        },
        {
          label: 'Expired Requests',
          icon: 'hourglass-end',
          value: MenuOption.Expired,
        },
      ];

      // Add Request List section
      menu.sections.push({
        label: 'Request List',
        icon: 'list-ul',
        value: WashListComponent.route,
        options: options,
      });

      this.gtmUtilsService
        .getGtmConfig('showSchneiderPortalCompletions')
        .then((value) => {
          if (value) {
            options.push({
              label: 'Schneider Portal Completions',
              icon: 'truck',
              value: MenuOption.SchneiderPortalCompletions,
            });
          }
        });

      // Add Schedule section
      menu.sections.push({
        label: 'Schedule',
        icon: 'calendar',
        value: 'schedule',
        options: [
          { label: 'Schedule', icon: 'calendar', value: MenuOption.Schedule },
        ],
      });

      // Add Shift Logs
      menu.sections.push({
        icon: 'table',
        label: 'Shift Logs',
        value: 'shift-logs',
        options: [
          {
            icon: 'table',
            label: 'Terminal Shift Logs',
            value: MenuOption.ShiftLogs,
          },
        ],
      });
    } else if (currentRole.toLowerCase().includes('depot')) {
      // does nothing
    } else {
      menu.sections.push({
        label: 'Request List',
        icon: 'list-ul',
        value: WashListComponent.route,
        options: [
          { label: 'Drafts', icon: 'edit', value: MenuOption.Draft },
          {
            label: 'Submitted',
            icon: 'paper-plane',
            value: MenuOption.Submitted,
          },
          {
            label: 'Needs Action',
            icon: 'flag',
            value: MenuOption.NeedsAction,
          },
          { label: 'Accepted', icon: 'star', value: MenuOption.Accepted },
          {
            label: 'Pending',
            icon: 'hourglass-half',
            value: MenuOption.Pending,
          },
          {
            label: 'In Progress',
            icon: 'sync-alt',
            value: MenuOption.InProgress,
          },
          {
            label: 'Completed',
            icon: 'check-circle',
            value: MenuOption.Completed,
          },
          { label: 'Canceled', icon: 'ban', value: MenuOption.Canceled },
          {
            label: 'Rejected',
            icon: 'times-circle',
            value: MenuOption.Rejected,
          },
        ],
      });
    }

    if (!currentRole.toLowerCase().includes('depot')) {
      menu.sections.push({
        label: 'Bulk Upload',
        icon: 'cloud-upload-alt',
        value: 'bulk-upload',
        options: [
          {
            label: 'Download Template',
            icon: 'file-download',
            value: MenuOption.DownloadTemplate,
          },
        ],
      });
    }

    if (
      currentRole.includes('-Manager') ||
      currentRole.includes('CSC') ||
      currentRole.includes('Ticket') ||
      currentRole.includes('Cleaner')
    ) {
      // Add Exterior Wash Offer
      menu.sections.push({
        label: 'Exterior Wash',
        icon: 'tint',
        value: 'exterior-wash-offer',
        options: [
          {
            label: 'Exterior Wash Offer',
            icon: 'tint',
            value: MenuOption.ExteriorWashOffer,
          },
        ],
      });

      menu.sections.push({
        label: 'Support',
        icon: 'question-circle',
        value: 'support',
        options: [
          { label: 'User Guide', icon: 'book', value: MenuOption.Wiki },
          {
            label: 'Customer Training Video',
            icon: 'video',
            value: MenuOption.Video,
          },
          {
            label: 'Terms and Conditions',
            icon: 'book',
            value: MenuOption.Terms,
          },
        ],
      });
    }

    if (currentRole.includes('Customer')) {
      menu.sections.push({
        icon: 'chart-line',
        label: 'Reports',
        value: 'reports',
        options: [
          {
            icon: 'chart-line',
            label: 'Wash Metrics',
            value: MenuOption.WashMetrics,
          },
        ],
      });

      menu.sections.push({
        label: 'Support',
        icon: 'question-circle',
        value: 'support',
        options: [
          {
            label: 'Customer Training Video',
            icon: 'video',
            value: MenuOption.Video,
          },
          {
            label: 'Terms and Conditions',
            icon: 'book',
            value: MenuOption.Terms,
          },
        ],
      });
    }

    if (currentRole.toLowerCase().includes('depot')) {
      menu.sections.push({
        label: 'Support',
        icon: 'question-circle',
        value: 'support',
        options: [
          { label: 'User Guide', icon: 'book', value: MenuOption.Wiki },
          {
            label: 'Terms and Conditions',
            icon: 'book',
            value: MenuOption.Terms,
          },
        ],
      });
    }

    if (currentRole.includes('Customer')) {
      menu.sections.push({
        label: 'Non-Conformity Report',
        icon: 'file',
        value: 'non-conformity-report',
        options: [
          { label: 'Opened', icon: 'folder-open', value: MenuOption.NcrOpened },
          {
            label: 'Completed',
            icon: 'check-circle',
            value: MenuOption.NcrCompleted,
          },
          { label: 'Cancelled', icon: 'ban', value: MenuOption.NcrCancelled },
        ],
      });
    } else {
      if (hasAllReportActions) {
        menu.sections.push({
          label: 'Non Conformity Report',
          icon: 'file',
          value: 'non-conformity-report',
          options: [
            {
              label: 'Opened',
              icon: 'folder-open',
              value: MenuOption.NcrOpened,
            },
            {
              label: 'In Analysis',
              icon: 'chart-bar',
              value: MenuOption.NcrInAnalysis,
            },
            {
              label: 'Waiting for Approval',
              icon: 'sync-alt',
              value: MenuOption.NcrWaitingForApproval,
            },
            {
              label: 'Completed',
              icon: 'check-circle',
              value: MenuOption.NcrCompleted,
            },
            { label: 'Cancelled', icon: 'ban', value: MenuOption.NcrCancelled },
          ],
        });
      } else {
        menu.sections.push({
          label: 'Non Conformity Report',
          icon: 'file',
          value: 'non-conformity-report',
          options: [
            {
              label: 'In Analysis',
              icon: 'chart-bar',
              value: MenuOption.NcrInAnalysis,
            },
            {
              label: 'Waiting for Approval',
              icon: 'sync-alt',
              value: MenuOption.NcrWaitingForApproval,
            },
            {
              label: 'Completed',
              icon: 'check-circle',
              value: MenuOption.NcrCompleted,
            },
            { label: 'Cancelled', icon: 'ban', value: MenuOption.NcrCancelled },
          ],
        });
      }
    }

    if (
      currentRole.includes('-Manager') ||
      currentRole.includes('CSC') ||
      currentRole.includes('Ticket') ||
      currentRole.includes('Cleaner')
    ) {
      menu.sections.push({
        label: 'My Account',
        icon: 'user',
        value: 'account',
        options: [
          {
            label: 'Notification Preferences',
            icon: 'bell',
            value: MenuOption.UserNotification,
          },
          { label: 'Logout', icon: 'sign-out-alt', value: MenuOption.Logout },
        ],
      });
    }

    if (currentRole.toLowerCase().includes('depot')) {
      menu.sections.push({
        label: 'My Account',
        icon: 'user',
        value: 'account',
        options: [
          { label: 'Logout', icon: 'sign-out-alt', value: MenuOption.Logout },
        ],
      });
    }

    if (currentRole.includes('Customer')) {
      menu.sections.push({
        label: 'My Account',
        icon: 'user',
        value: 'account',
        options: [
          {
            label: 'Company Notifications',
            icon: 'bell',
            value: MenuOption.BusinessPartnerNotification,
          },
          {
            label: 'Notification Preferences',
            icon: 'bell',
            value: MenuOption.UserNotification,
          },
          { label: 'Logout', icon: 'sign-out-alt', value: MenuOption.Logout },
        ],
      });
    }

    return menu;
  }

  changeBreakpointFlag(flag: boolean) {
    this._breakpointFlag.next(flag);
  }
}
