import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, skipWhile } from 'rxjs/operators';
import { ScheduleService } from '../schedule.service';
import { columnWidth } from '../schedule.model';
import { Subscription } from 'rxjs';

const skeletonBays = [
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
];

@Component({
  selector: 'app-in-progress',
  templateUrl: './in-progress.component.html',
  styleUrls: ['./in-progress.component.scss'],
})
export class InProgressComponent implements OnInit, OnDestroy {
  integrations: any;
  defaultColumnWidth = columnWidth;
  bays: any[];
  cards: any[] = [];
  isMoveMode = false;

  bays$: Subscription;
  cards$: Subscription;
  isMoveMode$: Subscription;

  constructor(private scheduleService: ScheduleService) {}

  ngOnInit() {
    this.isMoveMode$ = this.scheduleService.isMoveMode.subscribe((data) => {
      this.isMoveMode = data;
    });
    this.bays = skeletonBays;

    this.bays$ = this.scheduleService.bays.subscribe((data) => {
      this.bays = data;
    });

    this.cards$ = this.scheduleService.cards
      .pipe(
        skipWhile((_) => this.isMoveMode),
        map((data) => data.filter((item) => item.cardType === 'progress'))
      )
      .subscribe((data) => {
        this.cards = data || [];
        this.updateBaysAvailability();
      });
  }

  ngOnDestroy() {
    this.bays$.unsubscribe();
    this.cards$.unsubscribe();
    this.isMoveMode$.unsubscribe();
  }

  inProgressForBay(bayId: string) {
    return this.cards.find((item) => item.bayId === bayId);
  }

  updateBaysAvailability() {
    const updatedBays = this.bays.map((bay) => {
      let isOccupied: boolean;
      const cardInProgress = this.cards.find((item) => item.bayId === bay.id);

      if (!cardInProgress) {
        isOccupied = false;
      } else if (cardInProgress && cardInProgress.linked) {
        isOccupied = true;
      } else if (
        (cardInProgress && bay.dualType === 'SERIAL') ||
        bay.dualType === 'PARALLEL'
      ) {
        isOccupied = false;
      } else {
        isOccupied = true;
      }
      return {
        ...bay,
        isOccupied,
        cardInProgress,
      };
    });

    this.scheduleService.updateBays(updatedBays);
  }
}
