import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InternalNcrCreationComponent } from './internal-ncr-creation/internal-ncr-creation.component';
import { NcrListComponent } from './ncr-list/ncr-list.component';
import { NcrEditComponent } from './ncr-edit/ncr-edit.component';

const routes: Routes = [
  { path: 'edit/:id', component: NcrEditComponent },
  { path: 'create', component: InternalNcrCreationComponent },
  { path: 'list/:option', component: NcrListComponent },
  { path: '**', redirectTo: 'list/opened' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NonConformityReportRoutingModule {}
