import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ManagerRoleGuard } from '../core/auth/manager-role.guard';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ReportsPortalRedirectGuard {
  constructor(
    private managerRoleGuard: ManagerRoleGuard,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const canActivate = await this.managerRoleGuard.canActivate();
    if (canActivate) {
      window.location.href = environment.reportsPortal.url;
      return false;
    }

    return this.router.createUrlTree(['/']);
  }
}
