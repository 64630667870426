import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { Subscription } from 'rxjs';
import { columnWidth } from '../schedule.model';

const skeletonBays = [
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  bays: any[];
  bays$: Subscription;
  defaultColumnWidth = columnWidth;

  constructor(private scheduleService: ScheduleService) {}

  ngOnInit(): void {
    this.bays = skeletonBays;

    this.bays$ = this.scheduleService.bays.subscribe((data) => {
      this.bays = data;
    });
  }

  ngOnDestroy() {
    this.bays$.unsubscribe();
  }
}
