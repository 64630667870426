// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-maintenance {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 3fr 1fr;
  background-color: #f5f4f7;
}

.header {
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: flex-end;
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #cdcdcd;
}

.logo-area .logo {
  width: 85.5px;
  height: 32px;
}

.content-area {
  width: 80%;
  display: grid;
  place-self: center;
  justify-items: center;
  text-align: center;
}

.empty-row {
  height: 32px;
}

button {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/system-maintenance/system-maintenance.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,gCAAA;EACA,yBCoBS;ADrBX;;AAIA;EACE,cAAA;EACA,UAAA;EACA,aAAA;EACA,oCAAA;EAEA,qBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gCAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;AADF;;AAIA;EACE,UAAA;EACA,aAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,WAAA;AADF","sourcesContent":["@import '../../variables.scss';\n\n.system-maintenance {\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 3fr 1fr;\n  background-color: $magnolia;\n}\n\n.header {\n  margin: 0 auto;\n  width: 80%;\n  display: grid;\n  grid-template-columns: 1fr auto auto;\n  -webkit-box-align: end;\n  align-items: flex-end;\n  padding-top: 24px;\n  padding-bottom: 16px;\n  border-bottom: 2px solid $very-light-pink;\n}\n\n.logo-area .logo {\n  width: 85.5px;\n  height: 32px;\n}\n\n.content-area {\n  width: 80%;\n  display: grid;\n  place-self: center;\n  justify-items: center;\n  text-align: center;\n}\n\n.empty-row {\n  height: 32px;\n}\n\nbutton {\n  width: auto;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
