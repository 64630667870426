import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class QualaRoleGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    const canLoad = this.authService.hasQualaWorkerRole();

    if (canLoad) {
      return true;
    }

    this.router.navigateByUrl('/');
    return false;
  }
}
