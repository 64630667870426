// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fa-icon {
  padding-right: 3px;
  color: yellow;
}

.error-field {
  display: flex;
}
.error-field .errorMessage {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  color: #d60600;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/wash-list/shared/error-info/error-info.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;AADF;;AAIA;EACE,aAAA;AADF;AAEE;EACE,eAAA;EACA,iBAAA;EACA,aAAA;EACA,cCWE;ADXN","sourcesContent":["@import '../../../../../variables.scss';\n\nfa-icon {\n  padding-right: 3px;\n  color: yellow;\n}\n\n.error-field {\n  display: flex;\n  .errorMessage {\n    font-size: 16px;\n    font-weight: bold;\n    display: flex;\n    color: $red;\n  }\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
