import { Component, OnInit, OnDestroy } from '@angular/core';
import { HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from '../../core/localstorage.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/model/user.model';

@Component({
  selector: 'app-role-picker',
  templateUrl: './role-picker.component.html',
  styleUrls: ['./role-picker.component.scss'],
})
export class RolePickerComponent implements OnInit, OnDestroy {
  authenticateRoleForm: UntypedFormGroup;
  userRoleId$: Subscription;
  userRoleId: UntypedFormControl;
  private user: User;
  private wasInside = false;
  private isDepot: boolean = false;

  public userRoles: Array<any>;

  rolePickerIsEnabled: boolean;
  public userCurrentRole: string;

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    this.rolePickerIsEnabled = false;
  }

  ngOnInit() {
    this.createFormControls();
    this.createFormGroup();
    this.user = this.localStorageService.getUser();
    this.isDepot = this.user.shouldCallUserInfo;
    if (this.user) {
      this.userRoles = this.getUserRoleList(this.user);
      this.userCurrentRole = this.getRoleDisplayName(
        this.user.currentRole,
        this.user.roles
      );
    }
  }

  ngOnDestroy() {
    this.userRoleId$.unsubscribe();
  }

  createFormGroup() {
    this.authenticateRoleForm = new UntypedFormGroup({
      userRoleId: this.userRoleId,
    });
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.rolePickerIsEnabled = false;
    }
    this.wasInside = false;
  }

  @HostListener('document:keydown.escape')
  onEscapeKey() {
    this.rolePickerIsEnabled = false;
  }

  public pickRole() {
    this.rolePickerIsEnabled = !this.rolePickerIsEnabled;
    this.userRoleId.setValue(null);
  }

  private getRoleDisplayName(currentRole: string, userRoles: Array<any>) {
    if (this.isDepot) {
      const terminalNumber = currentRole.split('#T=')[1];
      const role = userRoles[0].terminals.find((item) => {
        // TODO: For now we'll only have one possible role, or RVP depot or depot
        return item.terminalNumber === terminalNumber;
      });
      return `${role.terminalName} ${userRoles[0].name.split('.')[1]}`;
    }
    const role = currentRole;
    const roleName = userRoles.find((item) => {
      return item[role];
    });
    if (roleName && roleName[role]) {
      return roleName[role];
    }
    return 'Choose the terminal / role';
  }

  createFormControls() {
    this.userRoleId = new UntypedFormControl('');

    this.userRoleId$ = this.userRoleId.valueChanges.subscribe((role) => {
      if (!role) {
        return;
      }

      if (role === this.user.currentRole) {
        this.rolePickerIsEnabled = false;
        return;
      }

      this.authService.changeRole(role).then((user) => {
        window.location.assign('/');
        this.userCurrentRole = this.getRoleDisplayName(
          user.currentRole,
          user.roles
        );
      });
      this.rolePickerIsEnabled = false;
    });
  }

  private getUserRoleList(user: User) {
    const roleList = [];

    if (this.isDepot) {
      const roleName = user.roles[0].name.split('.')[1];
      user.roles[0].terminals.forEach((role) => {
        roleList.push({
          id: `${roleName}#T=${Object.values(role)[0]}`,
          name: Object.values(role)[1],
        });
      });
    } else {
      user.roles.forEach((role) => {
        roleList.push({
          id: Object.keys(role)[0],
          name: Object.values(role)[0],
        });
      });
    }

    roleList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return roleList;
  }
}
