import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { MenuSectionComponent } from './menu/menu-section.component';
import { WashListComponent } from './wash-list/wash-list.component';
import { WashItemComponent } from './wash-list/wash-item/wash-item.component';
import { WashCreateComponent } from './wash-list/wash-create/wash-create.component';
import { WashRejectComponent } from './wash-list/wash-reject/wash-reject.component';
import { WashRejectWithHeelComponent } from './wash-list/wash-reject-with-heel/wash-reject-with-heel.component';
import { WashDetailsOverlayComponent } from './schedule/wash-details-overlay/wash-details-overlay.component';
import { WashEditComponent } from './wash-list/wash-edit/wash-edit.component';
import { WashHeelComponent } from './wash-list/wash-heel/wash-heel.component';
import { WashHeelManagementComponent } from './wash-list/wash-heel-management/wash-heel-management.component';
import { RolePickerComponent } from './role-picker/role-picker.component';
import { DashboardService } from './dashboard.service';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { WashRevisionComponent } from './wash-list/wash-revision/wash-revision.component';
import { ReportsComponent } from './reports/reports.component';
import { CreateContainerComponent } from './wash-list/wash-create/create-container/create-container.component';
import { UserPreferencesComponent } from './notification-preferences/user/user-preferences.component';
import { NonConformityReportModule } from './non-conformity-report/non-conformity-report.module';
import { SteamHeatDataFormComponent } from './wash-list/wash-create/steam-heat-data-form/steam-heat-data-form.component';
import { ServicePlanPopUpOverlayComponent } from './wash-list/shared/service-plan-pop-up-overlay/service-plan-pop-up-overlay.component';
import { ErrorInfoComponent } from './wash-list/shared/error-info/error-info.component';
import { ExteriorWashOfferComponent } from './exterior-wash-offer/exterior-wash-offer.component';
import { WashMetricsComponent } from './reports/wash-metrics/wash-metrics.component';
import { HomeComponent } from './home/home.component';
import { OperatorsDialogComponent } from './schedule/operators-dialog/operators-dialog.component';
import { LeanDailyManagementComponent } from './reports/lean-daily-management/lean-daily-management.component';
import { TerminalScorecardWashComponent } from './reports/terminal-scorecard-wash/terminal-scorecard-wash.component';
import { TerminalScorecardDepotComponent } from './reports/terminal-scorecard-depot/terminal-scorecard-depot.component';
import { TerminalScorecardIndustrialComponent } from './reports/terminal-scorecard-industrial/terminal-scorecard-industrial.component';
import { TerminalScorecardRailComponent } from './reports/terminal-scorecard-rail/terminal-scorecard-rail.component';
import { EhsWasteManagementTerminalComponent } from './reports/ehs-waste-management-terminal-view/ehs-waste-management-terminal-view.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
@NgModule({
  declarations: [
    DashboardComponent,
    MenuSectionComponent,
    WashListComponent,
    WashItemComponent,
    WashCreateComponent,
    WashRejectComponent,
    WashRejectWithHeelComponent,
    WashDetailsOverlayComponent,
    OperatorsDialogComponent,
    WashEditComponent,
    WashHeelComponent,
    WashHeelManagementComponent,
    RolePickerComponent,
    WashRevisionComponent,
    ReportsComponent,
    CreateContainerComponent,
    SteamHeatDataFormComponent,
    ServicePlanPopUpOverlayComponent,
    UserPreferencesComponent,
    ExteriorWashOfferComponent,
    ErrorInfoComponent,
    WashMetricsComponent,
    LeanDailyManagementComponent,
    TerminalScorecardWashComponent,
    TerminalScorecardDepotComponent,
    TerminalScorecardIndustrialComponent,
    TerminalScorecardRailComponent,
    EhsWasteManagementTerminalComponent,
    HomeComponent,
    TermsConditionsComponent,
  ],
  providers: [DashboardService, NgSelectConfig],
  imports: [
    SharedModule,
    DashboardRoutingModule,
    NgSelectModule,
    FormsModule,
    NonConformityReportModule,
  ],
  exports: [
    WashCreateComponent,
    WashRejectComponent,
    WashRejectWithHeelComponent,
    WashEditComponent,
    WashDetailsOverlayComponent,
    OperatorsDialogComponent,
  ],
})
export class DashboardModule {}
