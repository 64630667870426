import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class DispatcherRoleGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  private canLoadOrActivate():
    | Observable<boolean>
    | Promise<boolean>
    | boolean {
    const hasDispatcherRole = this.authService.hasDispatcherRole();

    return AuthGuard.canDoAction(hasDispatcherRole, this.router);
  }
}
