// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-component {
  display: grid;
  grid-template-rows: auto auto;
  padding: 32px;
}

.header {
  padding: 10px 0;
  border-top: 2px solid #dddcde;
}
.header > .title {
  margin: 0;
}

.agenda-area {
  margin-top: 16px;
}

.header-component {
  position: sticky;
  top: 0;
  z-index: 999;
}

.schedule {
  display: grid;
  grid-template-rows: auto auto;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/schedule/schedule.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6BAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,6BAAA;AACF;AAAE;EACE,SAAA;AAEJ;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,MAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,6BAAA;AACF","sourcesContent":[".schedule-component {\n  display: grid;\n  grid-template-rows: auto auto;\n  padding: 32px;\n}\n\n.header {\n  padding: 10px 0;\n  border-top: 2px solid #dddcde;\n  > .title {\n    margin: 0;\n  }\n}\n\n.agenda-area {\n  margin-top: 16px;\n}\n\n.header-component {\n  position: sticky;\n  top: 0;\n  z-index: 999;\n}\n\n.schedule {\n  display: grid;\n  grid-template-rows: auto auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
