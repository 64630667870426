import { UploadedFile } from 'src/app/shared/file-upload/file-upload.model';

export enum NonConformityReportStatus {
  Opened = 'OPENED',
  InAnalysis = 'IN_ANALYSIS',
  WaitingApproval = 'WAITING_APPROVAL',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}
export class CustomerInformation {
  contactName: string;
  email?: string;
  phone: string;
  businessPartnerName: string;
  orderNumber?: string;

  constructor(json: {
    contactName: string;
    email?: string;
    phone: string;
    businessPartnerName: string;
    orderNumber?: string;
  }) {
    this.contactName = json.contactName;
    this.email = json.email;
    this.phone = json.phone;
    this.businessPartnerName = json.businessPartnerName;
    this.orderNumber = json.orderNumber;
  }
}

export class ProblemReportedDateTime {
  dateIssued: number;
  dateDiscovered: number;

  constructor(json: any = {}) {
    this.dateIssued = json.dateIssued;
    this.dateDiscovered = json.dateDiscovered;
  }
}

export class MasterCustomer {
  customerMasterId: number;
  customerMasterName: string;

  constructor(json: any = {}) {
    this.customerMasterId = json.id;
    this.customerMasterName = json.name;
  }
}

export class QualaInformation {
  workOrder?: string;
  terminalName: string;
  terminalRegion?: string;
  terminalNumber?: string;

  constructor(json: any = {}) {
    this.workOrder = json.workOrder;
    this.terminalName = json.terminalName;
    this.terminalRegion = json.terminalRegion;
    this.terminalNumber = json.terminalNumber;
  }
}

export class PeopleInvolved {
  technician?: string;
  facilityManagerName?: string;
  shiftSupervisor?: string;
  namesOfPeopleInvolved?: Array<string>;
  shiftStartTime?: string;
  cleaner?: string;

  constructor(json: {
    technician?: string;
    facilityManagerName?: string;
    shiftSupervisor?: string;
    namesOfPeopleInvolved?: Array<string>;
    shiftStartTime?: string;
    cleaner?: string;
  }) {
    this.technician = json.technician;
    this.facilityManagerName = json.facilityManagerName;
    this.shiftSupervisor = json.shiftSupervisor;
    this.namesOfPeopleInvolved = json.namesOfPeopleInvolved || [];
    this.cleaner = json.cleaner;
    this.shiftStartTime = json.shiftStartTime;
  }
}

export class Tank {
  number?: string;
  isFoodGrade?: boolean;
  tankType?: string;
  washType?: string;

  constructor(json: any = {}) {
    this.number = json.number;
    this.isFoodGrade = json.isFoodGrade;
    this.tankType = json.tankType;
    this.washType = json.washType;
  }
}

export class Damage {
  hasBeenDamaged: boolean;
  whatWasDamaged?: string;
  howItGotDamaged?: string;
  repairCost?: number;
  driverName?: string;
  whoDidTheRepair?: string;
  receipt?: boolean;
  trailerNumber?: string;
  tractorNumber?: string;

  constructor(json: any = {}) {
    this.hasBeenDamaged = json.hasBeenDamaged;
    this.whatWasDamaged = json.whatWasDamaged;
    this.howItGotDamaged = json.howItGotDamaged;
    this.repairCost = json.repairCost;
    this.driverName = json.driverName;
    this.whoDidTheRepair = json.whoDidTheRepair;
    this.receipt = json.receipt;
    this.trailerNumber = json.trailerNumber;
    this.tractorNumber = json.tractorNumber;
  }
}

export class ActionRegistry {
  description: string;
  responsible: string;
  date: number;
  completionDate: number;

  constructor(json: {
    description: string;
    responsible: string;
    date: number;
    completionDate: number;
  }) {
    this.description = json.description;
    this.date = json.date;
    this.responsible = json.responsible;
    this.completionDate = json.completionDate;
  }
}

export class Responsible {
  name?: string;
  sameForAllActions: boolean;

  constructor(json: { name?: string; sameForAllActions: boolean }) {
    this.name = json.name;
    this.sameForAllActions = json.sameForAllActions;
  }
}

export class Investigation {
  immediateAction: ActionRegistry;
  correctiveAction: ActionRegistry;
  validationCorrectiveAction: ActionRegistry;
  responsible: Responsible;
  dateLastUpdate: number;

  constructor(json: any = {}) {
    this.immediateAction = json.immediateAction;
    this.correctiveAction = json.correctiveAction;
    this.validationCorrectiveAction = json.validationCorrectiveAction;
    this.responsible = json.responsible;
    this.dateLastUpdate = json.dateLastUpdate;
  }
}

export class DataFabricExecution {
  issueFields: Array<string>;
  warningStatus: boolean;

  constructor(json: any = {}) {
    this.issueFields = json.issueFields;
    this.warningStatus = json.warningStatus;
  }
}

export class FiveWhys {
  firstWhy: string;
  secondWhy: string;
  thirdWhy: string;
  fourthWhy: string;
  fifthWhy: string;
  rootCause: ActionRegistry;
  constructor(json: {
    firstWhy: string;
    secondWhy: string;
    thirdWhy: string;
    fourthWhy: string;
    fifthWhy: string;
    rootCause: {
      description: string;
      responsible: string;
      date: number;
      completionDate: number;
    };
  }) {
    this.rootCause = json.rootCause;
    this.firstWhy = json.firstWhy;
    this.secondWhy = json.secondWhy;
    this.thirdWhy = json.thirdWhy;
    this.fourthWhy = json.fourthWhy;
    this.fifthWhy = json.fifthWhy;
  }
}

export class Update {
  statusChanged: NonConformityReportStatus;
  newStatus: NonConformityReportStatus;
  responsibleId: string;
  responsibleName: string;
  date: number;

  constructor(json: any = {}) {
    this.statusChanged = json.statusChanged;
    this.newStatus = json.newStatus;
    this.responsibleId = json.responsibleId;
    this.responsibleName = json.responsibleName;
    this.date = json.date;
  }
}
export class CreatedBy {
  id?: string;
  name?: string;
  email?: string;
  userBusinessPartnerId?: string;
  userCustomerMasterId?: number;

  constructor(json: any = {}) {
    this.name = json.name;
    this.id = json.id;
    this.email = json.email;
    this.userBusinessPartnerId = json.userBusinessPartnerId;
    this.userCustomerMasterId = json.userMasterId;
  }
}

export class OriginalInformation {
  terminalId: string;
  terminalName: string;
  containerId: string;
  containerName: string;
  planId: string;
  planName: string;
  businessPartnerId?: string;
  businessPartnerName: string;
  containerOwnerId: string;
  containerOwnerName: string;
  customerMasterId?: number;

  constructor(json: any = {}) {
    this.terminalId = json.terminalId;
    this.terminalName = json.terminalName;
    this.containerId = json.containerId;
    this.containerName = json.containerName;
    this.planId = json.planId;
    this.planName = json.planName;
    this.businessPartnerId = json.businessPartnerId;
    this.businessPartnerName = json.businessPartnerName;
    this.containerOwnerId = json.containerOwnerId;
    this.containerOwnerName = json.containerOwnerName;
    this.customerMasterId = json.customerMasterId;
  }
}

export class NonConformityReport {
  id?: string;
  originalInformation?: OriginalInformation;
  customer?: CustomerInformation;
  problemReportedDateTime?: ProblemReportedDateTime;
  issueType?: string;
  dataFabricExecution?: DataFabricExecution;
  qualaInformation?: QualaInformation;
  tankInformation?: Tank;
  wasFoodGradeClean?: boolean;
  issueDescription?: string;
  hasTheOwnerBeenNotified?: boolean;
  damage?: Damage;
  whoHaveBeenNotified?: Array<string>;
  whoGotHurt?: string;
  whoHadSignedStatement?: Array<string>;
  investigation?: Investigation;
  fiveWhys?: FiveWhys;
  incidentResponsibility?: string;
  remedialTraining?: string;
  wasLateCompleted?: boolean;
  madeByQualaWorker?: boolean;
  selfReported?: boolean;
  resolutionToBeDetermined?: boolean;
  status?: NonConformityReportStatus;
  assignTo?: string;
  createdAt?: number;
  createdBy?: CreatedBy;
  dueDate?: number;
  completedAt?: number;
  lastUpdateAt?: number;
  lastUpdateBy?: string;
  daysToComplete?: number;
  approvedById?: string;
  cancellationReason?: string;
  historic?: Array<Update>;
  wasAnyoneInjured?: boolean;
  uploadedFiles?: UploadedFile[];
  peopleInvolved?: PeopleInvolved;
  equipmentOwner?: string;
  lineOfBusiness?: string;
  ncrLead?: string;
  incidentCategory?: string;
  correctiveActionCategory?: string;
  shipperName?: string;
  businessPartnerId?: string;
  isReassignment?: boolean;
  reassignmentInfo?: {
    reassignComments?: string;
  };
  customerMasterId?: number;

  constructor(json: any = {}) {
    this.id = json.id;
    this.originalInformation =
      json.originalInformation &&
      new OriginalInformation(json.originalInformation);
    this.customer = json.customer && new CustomerInformation(json.customer);
    this.peopleInvolved =
      json.peopleInvolved && new PeopleInvolved(json.peopleInvolved);
    this.problemReportedDateTime =
      json.problemReportedDateTime &&
      new ProblemReportedDateTime(json.problemReportedDateTime);
    this.issueType = json.issueType;
    this.qualaInformation =
      json.qualaInformation && new QualaInformation(json.qualaInformation);
    this.tankInformation =
      json.tankInformation && new Tank(json.tankInformation);
    this.wasFoodGradeClean = json.wasFoodGradeClean;
    this.issueDescription = json.issueDescription;
    this.dataFabricExecution = json.dataFabricExecution;
    this.hasTheOwnerBeenNotified = json.hasTheOwnerBeenNotified;
    this.damage = json.damage && new Damage(json.damage);
    this.whoHaveBeenNotified = json.whoHaveBeenNotified || [];
    this.whoGotHurt = json.whoGotHurt;
    this.whoHadSignedStatement = json.whoHadSignedStatement || [];
    this.investigation =
      json.investigation && new Investigation(json.investigation);
    this.fiveWhys = json.fiveWhys && new FiveWhys(json.fiveWhys);
    this.incidentResponsibility = json.incidentResponsibility;
    this.remedialTraining = json.remedialTraining;
    this.wasLateCompleted = json.wasLateCompleted;
    this.madeByQualaWorker = json.madeByQualaWorker;
    this.selfReported = json.selfReported;
    this.resolutionToBeDetermined = json.resolutionToBeDetermined;
    this.status = json.status;
    this.assignTo = json.assignTo;
    this.createdAt = json.createdAt;
    this.createdBy = json.createdBy && new CreatedBy(json.createdBy);
    this.dueDate = json.dueDate;
    this.completedAt = json.completedAt;
    this.lastUpdateAt = json.lastUpdateAt;
    this.lastUpdateBy = json.lastUpdateBy;
    this.daysToComplete = json.daysToComplete;
    this.approvedById = json.approvedById;
    this.cancellationReason = json.cancellationReason;
    this.historic = json.historic;
    this.wasAnyoneInjured = json.wasAnyoneInjured;
    this.uploadedFiles = json.uploadedFiles || [];
    this.equipmentOwner = json.equipmentOwner;
    this.lineOfBusiness = json.lineOfBusiness;
    this.ncrLead = json.ncrLead;
    this.incidentCategory = json.incidentCategory;
    this.correctiveActionCategory = json.correctiveActionCategory;
    this.shipperName = json.shipperName;
    this.businessPartnerId = json.businessPartnerId;
    this.isReassignment = json.isReassignment;
    this.reassignmentInfo = json.reassignmentInfo;
    this.customerMasterId = json.customerMasterId;
  }
}

export class ReducedNonConformityReport {
  id?: string;
  customer: CustomerInformation;
  problemReportedDateTime?: ProblemReportedDateTime;
  qcNcrId?: string;
  qcTerminal?: string;
  createdByDataFabric?: boolean;
  issues?: string;
  dataFabricExecution?: DataFabricExecution;
  issueType?: string;
  qualaInformation: QualaInformation;
  tankInformation?: Tank;
  issueDescription?: string;
  createdBy: CreatedBy;
  createdAt?: number;
  cancellationReason?: string;
  resolutionToBeDetermined?: boolean;
  peopleInvolved?: PeopleInvolved;
  equipmentOwner?: string;
  wasAnyoneInjured?: boolean;
  whoGotHurt?: string;
  dueDate?: number;
  wasLateCompleted?: boolean;
  assignTo?: string;
  assignToId?: string;
  madeByQualaWorker?: boolean;
  lineOfBusiness?: string;
  ncrLead?: string;
  ncrLeadId?: string;
  incidentCategory?: string;
  correctiveActionCategory?: string;
  shipperName?: string;
  uploadedFiles?: UploadedFile[];
  businessPartnerId?: string;
  isReassignment?: boolean;
  reassignmentInfo?: {
    reassignComments?: string;
  };
  customerMasterId?: number;

  constructor(json: any = {}) {
    this.id = json.id;
    this.customer = json.customer && new CustomerInformation(json.customer);
    this.problemReportedDateTime =
      json.problemReportedDateTime &&
      new ProblemReportedDateTime(json.problemReportedDateTime);
    this.qcNcrId = json.qcNcrId;
    this.qcTerminal = json.qcTerminal;
    this.createdByDataFabric = json.createdByDataFabric;
    this.issues = json.issues;
    this.dataFabricExecution = json.dataFabricExecution;
    this.issueType = json.issueType;
    this.qualaInformation =
      json.qualaInformation && new QualaInformation(json.qualaInformation);
    this.tankInformation =
      json.tankInformation && new Tank(json.tankInformation);
    this.issueDescription = json.issueDescription;
    this.createdBy = json.createdBy && new CreatedBy(json.createdBy);
    this.createdAt = json.createdAt;
    this.cancellationReason = json.cancellationReason;
    this.resolutionToBeDetermined = json.resolutionToBeDetermined;
    this.peopleInvolved =
      json.peopleInvolved && new PeopleInvolved(json.peopleInvolved);
    this.equipmentOwner = json.equipmentOwner;
    this.wasAnyoneInjured = json.wasAnyoneInjured;
    this.whoGotHurt = json.whoGotHurt;
    this.dueDate = json.dueDate;
    this.wasLateCompleted = json.wasLateCompleted;
    this.assignTo = json.assignTo;
    this.madeByQualaWorker = json.madeByQualaWorker;
    this.lineOfBusiness = json.lineOfBusiness;
    this.ncrLead = json.ncrLead;
    this.incidentCategory = json.incidentCategory;
    this.correctiveActionCategory = json.correctiveActionCategory;
    this.shipperName = json.shipperName;
    this.uploadedFiles = json.uploadedFiles || [];
    this.businessPartnerId = json.businessPartnerId;
    this.isReassignment = json.isReassignment;
    this.reassignmentInfo = json.reassignmentInfo;
    this.customerMasterId = json.customerMasterId;
  }
}
