import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SystemService } from './core/system.service';
import { PageTitle } from './dashboard/menu/menu.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private systemService: SystemService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.systemService.maintenance.subscribe((hasMaintenance: boolean) => {
      if (hasMaintenance) {
        this.router.navigate(['/system-maintenance']);
      }
    });
    // set Page Title and push GTM data layer for every visited page
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        let key = item.url.split('/').pop();
        if (!key) {
          key = 'default';
        }
        const isNCRPage = item.url.includes('non-conformity-report');
        const isExterior = item.url.includes('exterior-wash');
        this.setPageTitle(key, isNCRPage, isExterior);
        const gtmTag = {
          event: 'pageview',
          dp: item.url,
          dh: window.location.host,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  setPageTitle(key: string, isNCRPage: boolean, isExterior: boolean): void {
    if (isNCRPage) {
      switch (key) {
        case 'opened':
          key = 'ncrOpened';
          break;
        case 'in-analysis':
          key = 'ncrInAnalysis';
          break;
        case 'waiting-for-approval':
          key = 'ncrWaitingForApproval';
          break;
        case 'completed':
          key = 'ncrCompleted';
          break;
        case 'cancelled':
          key = 'ncrCancelled';
          break;
        default:
          key = key.includes('create') ? 'ncrCreation' : '';
          break;
      }
    } else if (isExterior) {
      if (key.includes('accept')) {
        key = 'exteriorWashAccept';
      } else if (key.includes('decline')) {
        key = 'exteriorWashDecline';
      } else {
        key = 'exteriorWashOffer';
      }
    } else {
      switch (key) {
        case 'external-ncr':
          key = 'ncrCreation';
          break;
        case 'user-notification':
          key = 'userNotification';
          break;
        case 'business-partner-notification':
          key = 'businessPartnerNotification';
          break;
        case 'exterior-wash-offer':
          key = 'exteriorWashOffer';
          break;
        default:
          break;
      }
    }
    this.title.setTitle(PageTitle[key]);
  }
}
