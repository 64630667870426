// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-component {
  display: grid;
  grid-template-rows: 50px auto;
}

.header-component .header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 192px;
  grid-auto-columns: 192px;
  border: solid 2px black;
  background-color: black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 9;
}

.header .header-bay {
  padding: 0 16px;
  font-weight: 900;
  display: flex;
  align-items: center;
}

.header .header-bay:first-child {
  border-top-left-radius: 16px;
}

.header .header-bay:last-child {
  border-top-right-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/schedule/header/header.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,6BAAA;AADF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,4BCmBmB;EDlBnB,wBCkBmB;EDjBnB,uBAAA;EACA,uBAAA;EACA,4BAAA;EACA,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;AAHF;;AAMA;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAHF;;AAMA;EACE,4BAAA;AAHF;;AAMA;EACE,6BAAA;AAHF","sourcesContent":["@import '../../../../variables.scss';\n\n.header-component {\n  display: grid;\n  grid-template-rows: 50px auto;\n}\n\n// Black area\n\n.header-component .header {\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: $default-slot-width;\n  grid-auto-columns: $default-slot-width;\n  border: solid 2px black;\n  background-color: black;\n  border-top-left-radius: 16px;\n  border-top-right-radius: 16px;\n  color: white;\n  position: sticky;\n  top: 0;\n  z-index: 9;\n}\n\n.header .header-bay {\n  padding: 0 16px;\n  font-weight: 900;\n  display: flex;\n  align-items: center;\n}\n\n.header .header-bay:first-child {\n  border-top-left-radius: 16px;\n}\n\n.header .header-bay:last-child {\n  border-top-right-radius: 16px;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
