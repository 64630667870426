/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type LinkedUpdateCardsInput = {
  id: string;
  bayId?: string | null;
  terminal?: string | null;
  terminalId?: string | null;
  bayName?: string | null;
  compatibleBays?: Array<string | null> | null;
  incompatibleBays?: Array<IncompatibleBaysInput | null> | null;
  containerNumber?: string | null;
  serviceType?: string | null;
  status?: string | null;
  duration?: number | null;
  cardType?: string | null;
  startTime?: number | null;
  finishTime?: number | null;
  needByTime?: number | null;
  trailerArrivalTime?: number | null;
  arrivalTime?: number | null;
  flagMissingNeedByTime?: boolean | null;
  flagBeforeArriving?: boolean | null;
  flagAlmostOnNeedByTime?: boolean | null;
  flagMissingVelocitySLA?: boolean | null;
  availableActionsStart?: boolean | null;
  availableActionsStop?: boolean | null;
  availableActionsPause?: boolean | null;
  availableActionsHold?: boolean | null;
  availableActionsMove?: boolean | null;
  workOrderCreated?: boolean | null;
  reason?: string | null;
  specialInstructions?: string | null;
  lastContained?: string | null;
  linkedCardId?: string | null;
  linkType?: string | null;
  linkedCardType?: string | null;
  linked?: UpdateCardsInput | null;
  flareRequired?: boolean | null;
  isOutOfShift?: boolean | null;
  isATwoDaysSchedule?: boolean | null;
  exteriorWash?: boolean | null;
  isHeelPreApproved?: boolean | null;
  operatedById?: string | null;
  tankOwnerId?: string | null;
  createdByEtendo?: boolean | null;
  workOrderId?: string | null;
  ectCustomerHasViewed?: boolean | null;
  foodGrade?: boolean | null;
  containsNitrogenComp1?: boolean | null;
  containsNitrogenComp2?: boolean | null;
  containsNitrogenComp3?: boolean | null;
  containsNitrogenComp4?: boolean | null;
  containsNitrogenComp5?: boolean | null;
  nitrogen?: boolean | null;
};

export type IncompatibleBaysInput = {
  bayId?: string | null;
  failedValidations?: Array<string | null> | null;
};

export type UpdateCardsInput = {
  id?: string | null;
  bayId?: string | null;
  terminal?: string | null;
  terminalId?: string | null;
  bayName?: string | null;
  compatibleBays?: Array<string | null> | null;
  incompatibleBays?: Array<IncompatibleBaysInput | null> | null;
  containerNumber?: string | null;
  serviceType?: string | null;
  status?: string | null;
  duration?: number | null;
  cardType?: string | null;
  startTime?: number | null;
  finishTime?: number | null;
  needByTime?: number | null;
  trailerArrivalTime?: number | null;
  arrivalTime?: number | null;
  flagMissingNeedByTime?: boolean | null;
  flagBeforeArriving?: boolean | null;
  flagAlmostOnNeedByTime?: boolean | null;
  flagMissingVelocitySLA?: boolean | null;
  availableActionsStart?: boolean | null;
  availableActionsStop?: boolean | null;
  availableActionsPause?: boolean | null;
  availableActionsHold?: boolean | null;
  availableActionsMove?: boolean | null;
  workOrderCreated?: boolean | null;
  reason?: string | null;
  specialInstructions?: string | null;
  linkedCardId?: string | null;
  linkType?: string | null;
  linkedCardType?: string | null;
  lastContained?: string | null;
  flareRequired?: boolean | null;
  isOutOfShift?: boolean | null;
  isATwoDaysSchedule?: boolean | null;
  exteriorWash?: boolean | null;
  isHeelPreApproved?: boolean | null;
  operatedById?: string | null;
  tankOwnerId?: string | null;
  createdByEtendo?: boolean | null;
  workOrderId?: string | null;
  ectCustomerHasViewed?: boolean | null;
  foodGrade?: boolean | null;
  containsNitrogenComp1?: boolean | null;
  containsNitrogenComp2?: boolean | null;
  containsNitrogenComp3?: boolean | null;
  containsNitrogenComp4?: boolean | null;
  containsNitrogenComp5?: boolean | null;
  nitrogen?: boolean | null;
};

export type RequestsConversionStatusInput = {
  requestId?: string | null;
  conversionStatus?: ConversionStatuses | null;
  conversionMessage?: string | null;
};

export enum ConversionStatuses {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  MANUAL_CONVERSION_SELECTED = 'MANUAL_CONVERSION_SELECTED',
}

export type RequestsCompletionStatusInput = {
  requestId?: string | null;
  completionStatus?: SchneiderCompletionStatus | null;
  completionMessage?: string | null;
};

export enum SchneiderCompletionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
}

export type NotifyMaintenanceStatusMutation = {
  __typename: 'MaintenanceStatus';
  id: string;
  hasMaintenance: boolean | null;
};

export type NotifyRecentOperatorsMutation = {
  __typename: 'RecentOperators';
  terminalId: string | null;
  users: Array<string | null> | null;
};

export type NotifyAgendaChangesMutation = {
  __typename: 'AgendaCards';
  terminalId: string | null;
  cards: Array<{
    __typename: 'LinkedScheduledRequest';
    id: string | null;
    bayId: string | null;
    bayName: string | null;
    compatibleBays: Array<string | null> | null;
    incompatibleBays: Array<{
      __typename: 'IncompatibleBay';
      bayId: string | null;
      failedValidations: Array<string | null> | null;
    } | null> | null;
    containerNumber: string | null;
    serviceType: string | null;
    status: string | null;
    duration: number | null;
    cardType: string | null;
    startTime: number | null;
    finishTime: number | null;
    needByTime: number | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    flagMissingNeedByTime: boolean | null;
    flagBeforeArriving: boolean | null;
    flagAlmostOnNeedByTime: boolean | null;
    flagMissingVelocitySLA: boolean | null;
    availableActionsStart: boolean | null;
    availableActionsStop: boolean | null;
    availableActionsPause: boolean | null;
    availableActionsHold: boolean | null;
    availableActionsMove: boolean | null;
    terminal: string | null;
    terminalId: string | null;
    lastContained: string | null;
    workOrderCreated: boolean | null;
    reason: string | null;
    specialInstructions: string | null;
    linkedCardId: string | null;
    linkType: string | null;
    linkedCardType: string | null;
    linked: {
      __typename: 'ScheduledRequest';
      id: string | null;
      bayId: string | null;
      bayName: string | null;
      compatibleBays: Array<string | null> | null;
      incompatibleBays: Array<{
        __typename: 'IncompatibleBay';
        bayId: string | null;
        failedValidations: Array<string | null> | null;
      } | null> | null;
      containerNumber: string | null;
      serviceType: string | null;
      status: string | null;
      duration: number | null;
      cardType: string | null;
      startTime: number | null;
      finishTime: number | null;
      needByTime: number | null;
      trailerArrivalTime: number | null;
      arrivalTime: number | null;
      flagMissingNeedByTime: boolean | null;
      flagBeforeArriving: boolean | null;
      flagAlmostOnNeedByTime: boolean | null;
      flagMissingVelocitySLA: boolean | null;
      availableActionsStart: boolean | null;
      availableActionsStop: boolean | null;
      availableActionsPause: boolean | null;
      availableActionsHold: boolean | null;
      availableActionsMove: boolean | null;
      terminal: string | null;
      terminalId: string | null;
      lastContained: string | null;
      workOrderCreated: boolean | null;
      reason: string | null;
      specialInstructions: string | null;
      linkedCardId: string | null;
      linkType: string | null;
      linkedCardType: string | null;
      flareRequired: boolean | null;
      isOutOfShift: boolean | null;
      isATwoDaysSchedule: boolean | null;
      exteriorWash: boolean | null;
      isHeelPreApproved: boolean | null;
      operatedById: string | null;
      tankOwnerId: string | null;
      createdByEtendo: boolean | null;
      workOrderId: string | null;
      ectCustomerHasViewed: boolean | null;
      foodGrade: boolean | null;
      containsNitrogenComp1: boolean | null;
      containsNitrogenComp2: boolean | null;
      containsNitrogenComp3: boolean | null;
      containsNitrogenComp4: boolean | null;
      containsNitrogenComp5: boolean | null;
      nitrogen: boolean | null;
    } | null;
    flareRequired: boolean | null;
    isOutOfShift: boolean | null;
    isATwoDaysSchedule: boolean | null;
    exteriorWash: boolean | null;
    isHeelPreApproved: boolean | null;
    operatedById: string | null;
    tankOwnerId: string | null;
    createdByEtendo: boolean | null;
    workOrderId: string | null;
    ectCustomerHasViewed: boolean | null;
    foodGrade: boolean | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    nitrogen: boolean | null;
  } | null> | null;
};

export type NotifyOrderConversionStatusMutation = {
  __typename: 'ConvertingRequests';
  terminalId: string | null;
  requests: Array<{
    __typename: 'RequestsConversionStatus';
    requestId: string | null;
    conversionStatus: ConversionStatuses | null;
    conversionMessage: string | null;
  } | null> | null;
};

export type NotifySchneiderCompletionStatusMutation = {
  __typename: 'ScheneiderCompletionRequest';
  terminalId: string | null;
  requests: Array<{
    __typename: 'RequestsSchneiderCompletionStatus';
    requestId: string | null;
    completionStatus: SchneiderCompletionStatus | null;
    completionMessage: string | null;
  } | null> | null;
};

export type NotifyShiftChangesMutation = {
  __typename: 'TerminalId';
  terminalId: string | null;
};

export type GetRecentOperatorsQuery = {
  __typename: 'RecentOperators';
  terminalId: string | null;
  users: Array<string | null> | null;
};

export type ListWashRequestQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetWashRequestByIdQuery = {
  __typename: 'WashRequest';
  id: string;
  createdByRole: string | null;
  createdBy: string | null;
  lastUpdateTime: number | null;
  lastUpdatedBy: string | null;
  terminal: string | null;
  terminalId: string | null;
  status: string | null;
  operatedBy: string | null;
  operatedById: string | null;
  tankOwner: string | null;
  tankOwnerId: string | null;
  phoneNumber: string | null;
  tankNumber: string | null;
  tankId: string | null;
  trailerArrivalTime: number | null;
  arrivalTime: number | null;
  needByTime: number | null;
  serviceType: string | null;
  serviceTypeId: string | null;
  serviceTypeOntraxId: string | null;
  foodGrade: boolean | null;
  kosher: boolean | null;
  specialInstructions: string | null;
  tankType: string | null;
  tankTypeId: string | null;
  compartmentsQty: number | null;
  lastContainedProductComp1Id: string | null;
  lastContainedProductComp2Id: string | null;
  lastContainedProductComp3Id: string | null;
  lastContainedProductComp4Id: string | null;
  lastContainedProductComp5Id: string | null;
  lastContainedProductComp1Name: string | null;
  lastContainedProductComp2Name: string | null;
  lastContainedProductComp3Name: string | null;
  lastContainedProductComp4Name: string | null;
  lastContainedProductComp5Name: string | null;
  containsNitrogenComp1: boolean | null;
  containsNitrogenComp2: boolean | null;
  containsNitrogenComp3: boolean | null;
  containsNitrogenComp4: boolean | null;
  containsNitrogenComp5: boolean | null;
  serviceTypeComp1: string | null;
  serviceTypeComp2: string | null;
  serviceTypeComp3: string | null;
  serviceTypeComp4: string | null;
  serviceTypeComp5: string | null;
  specialInstructionsComp1: string | null;
  specialInstructionsComp2: string | null;
  specialInstructionsComp3: string | null;
  specialInstructionsComp4: string | null;
  specialInstructionsComp5: string | null;
  lastContainedProduct1Id: string | null;
  lastContainedProduct2Id: string | null;
  lastContainedProduct3Id: string | null;
  lastContainedProduct1Name: string | null;
  lastContainedProduct2Name: string | null;
  lastContainedProduct3Name: string | null;
  nitrogen: boolean | null;
  tractorNumber: string | null;
  pumpQty: number | null;
  hosesQty: number | null;
  fittingsQty: number | null;
  ladingBill: string | null;
  poNumber: string | null;
  washBillToTerminal: string | null;
  washBillToTerminalId: string | null;
  completeTime: number | null;
  startTime: number | null;
  containerArrived: boolean | null;
  containerPickedUp: boolean | null;
  pendingHeelApproval: boolean | null;
  flareRequired: boolean | null;
  files: string | null;
  allowedActions: string | null;
  lastContained1: string | null;
  lastContained2: string | null;
  lastContained3: string | null;
  expectedCleaningStart: number | null;
  exceptionType: string | null;
  wasteType: string | null;
  heelAmount: string | null;
  heelCost: string | null;
  laborHours: string | null;
  laborCost: string | null;
  steam: string | null;
  steamCost: string | null;
  isCod: boolean | null;
  specialPrep: boolean | null;
  needMoreInfo: boolean | null;
  temperature: string | null;
  poNumberForPrep: string | null;
  createdByBulkUpload: boolean | null;
  rejectionReason: string | null;
  rejectionReasonCode: string | null;
  servicePlan: {
    __typename: 'ServicePlan';
    servicePlanName: string | null;
  } | null;
  recommendedServicePlan: {
    __typename: 'ServicePlan';
    servicePlanName: string | null;
  } | null;
  wasServicePlanChanged: boolean | null;
  reasonOfChange: string | null;
  reasonOfChangeCode: string | null;
  customerComments: string | null;
  isHeelPreApproved: boolean | null;
  workOrder: string | null;
  workOrderId: string | null;
  createdByEtendo: boolean | null;
  confinedEntry: boolean | null;
  confinedEntryType: string | null;
  workPerformedBy: {
    __typename: 'WorkPerformedBy';
    id: string | null;
    confinedEntryOperatorName: string | null;
  } | null;
  washRequestIdOpenBravo: string | null;
};

export type GetBusinessPartnerNotificationPreferencesByIdQuery = {
  __typename: 'BusinessPartnerNotificationPreferences';
  businessPartnerId: string;
  bulkUploadSuccess: boolean | null;
  bulkUploadNeedAdjustment: boolean | null;
  bulkUploadFailedParseEmailRecipient: boolean | null;
  bulkUploadFailedGetWarehouse: boolean | null;
  BulkUploadCouldNotProcessEmail: boolean | null;
  bulkUploadWrongFormat: boolean | null;
  requestAcceptedByQuala: boolean | null;
  requestRejected: boolean | null;
  requestCompleted: boolean | null;
  requestUpdated: boolean | null;
  requestNeedHeelApproval: boolean | null;
  requestHeelMoreInfoProvided: boolean | null;
  servicePlanNotAvailable: boolean | null;
  outsideWorkingHours: boolean | null;
  exteriorWashOffer: boolean | null;
  exteriorWashResponse: boolean | null;
};

export type GetUserNotificationPreferencesByIdQuery = {
  __typename: 'UserNotificationPreferences';
  aduserId: string;
  forwardUsersIds: Array<string | null> | null;
};

export type ListBusinessPartnerQuery = {
  __typename: 'ListBusinessPartnerResult';
  items: Array<{
    __typename: 'BusinessPartner';
    id: string;
    name: string | null;
    contmngPOFormat: string | null;
    contmngPOFormathelp: string | null;
    customerBlocking: string | null;
    contmngCustomernotes: string | null;
    contmngCustomernotesParsed: string | null;
    customer: string | null;
    summaryLevel: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListTypeOfServiceQuery = {
  __typename: 'TypeOfService';
  id: string;
  name: string | null;
  obId: string | null;
  displayName: string | null;
  key: string | null;
};

export type ListTypeOfTankQuery = {
  __typename: 'TypeOfTank';
  id: string;
  name: string | null;
  aecdoFoodgradectrtypeName: string | null;
};

export type ListCustomerProductTypesQuery = {
  __typename: 'ListCustomerProductTypes';
  items: Array<{
    __typename: 'CustomerProductType';
    containerTypeId: string | null;
    containerTypeName: string | null;
    productId: string | null;
    productName: string | null;
    productCategoryName: string | null;
    containerCategoryName: string | null;
    customerProductType: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetBillToTerminalQuery = {
  __typename: 'ListBillToTerminalResult';
  items: Array<{
    __typename: 'BillToTerminal';
    id: string;
    name: string | null;
    businessPartnerId: string | null;
    businessPartnerName: string | null;
    locationAddressId: string | null;
    locationAddressName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetTankQuery = {
  __typename: 'ListTankResult';
  items: Array<{
    __typename: 'Tank';
    id: string;
    businessPartnerId: string | null;
    businessPartnerName: string | null;
    compartments: string | null;
    containerTypeId: string | null;
    containerTypeName: string | null;
    operatedById: string | null;
    operatedByName: string | null;
    searchKey: string | null;
    warehouseId: string | null;
    warehouseName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListProductContentQuery = {
  __typename: 'ListProductContentResult';
  items: Array<{
    __typename: 'ProductContent';
    id: string;
    searchKey: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetActiveWarehousesQuery = {
  __typename: 'ListWarehouse';
  items: Array<{
    __typename: 'Warehouse';
    id: string;
    active: string | null;
    searchKey: string | null;
    terminalName: string | null;
    terminalNameDisplay: string | null;
    terminalNumber: string | null;
    terminalNumberDisplay: string | null;
    terminalLaunch: number | null;
    operationalRegion: string | null;
    linesOfBusiness: Array<string | null> | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAllWarehousesQuery = {
  __typename: 'ListWarehouse';
  items: Array<{
    __typename: 'Warehouse';
    id: string;
    active: string | null;
    searchKey: string | null;
    terminalName: string | null;
    terminalNameDisplay: string | null;
    terminalNumber: string | null;
    terminalNumberDisplay: string | null;
    terminalLaunch: number | null;
    operationalRegion: string | null;
    linesOfBusiness: Array<string | null> | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListRejectionReasonQuery = {
  __typename: 'RejectionReasonWithToken';
  items: Array<{
    __typename: 'RejectionReason';
    id: string | null;
    code: string | null;
    displayName: string | null;
    isVisible: boolean | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListReasonOfChangeQuery = {
  __typename: 'ReasonOfChangeWithToken';
  items: Array<{
    __typename: 'ReasonOfChange';
    code: string | null;
    displayName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListMissedNeedByTimeReasonQuery = {
  __typename: 'MissedNeedByTimeReasonWithToken';
  items: Array<{
    __typename: 'MissedNeedByTimeReason';
    order: string | null;
    code: string | null;
    displayName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrCorrectiveActionCategoryQuery = {
  __typename: 'NcrCorrectiveActionCategoryWithToken';
  items: Array<{
    __typename: 'NcrCorrectiveActionCategory';
    order: number | null;
    code: string | null;
    displayName: string | null;
    rawName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrIncidentCategoryQuery = {
  __typename: 'NcrIncidentCategoryWithToken';
  items: Array<{
    __typename: 'NcrIncidentCategory';
    order: number | null;
    code: string | null;
    displayName: string | null;
    rawName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrIncidentResponsibilityQuery = {
  __typename: 'NcrIncidentResponsibilityWithToken';
  items: Array<{
    __typename: 'NcrIncidentResponsibility';
    order: number | null;
    code: string | null;
    displayName: string | null;
    rawName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrShiftStartTimesQuery = {
  __typename: 'NcrShiftStartTimesWithToken';
  items: Array<{
    __typename: 'NcrShiftStartTimes';
    order: number | null;
    displayName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrRemedialTrainingProvidedQuery = {
  __typename: 'NcrRemedialTrainingProvidedWithToken';
  items: Array<{
    __typename: 'NcrRemedialTrainingProvided';
    order: number | null;
    code: string | null;
    displayName: string | null;
    rawName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrIssueTypeQuery = {
  __typename: 'NcrIssueTypeWithToken';
  items: Array<{
    __typename: 'NcrIssueType';
    order: number | null;
    code: string | null;
    displayName: string | null;
    rawName: string | null;
    possibleContaminationOrDamage: boolean | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrSignedStatementQuery = {
  __typename: 'NcrSignedStatementWithToken';
  items: Array<{
    __typename: 'NcrSignedStatement';
    order: number | null;
    code: string | null;
    displayName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListNcrWhoHaveBeenNotifiedQuery = {
  __typename: 'NcrWhoHaveBeenNotifiedWithToken';
  items: Array<{
    __typename: 'NcrWhoHaveBeenNotified';
    order: number | null;
    code: string | null;
    displayName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetDraftByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetNewRequestsByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetNeedsActionByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetPendingByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetOnHoldByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAcceptedByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetInProgressByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCompletedByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCanceledByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetRejectedByTerminalQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetDraftByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCreationByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetSubmittedByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetNeedsActionByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAcceptedByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetInProgressByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCompletedByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCanceledByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetRejectedByBusinessPartnerQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetOperatorsQuery = {
  __typename: 'Operators';
  items: Array<{
    __typename: 'Operator';
    userContactId: string | null;
    roleId: string | null;
    roleName: string | null;
    userContactName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetExistingWashRequestsQuery = {
  __typename: 'washRequestListWithToken';
  items: Array<{
    __typename: 'WashRequest';
    id: string;
    createdByRole: string | null;
    createdBy: string | null;
    lastUpdateTime: number | null;
    lastUpdatedBy: string | null;
    terminal: string | null;
    terminalId: string | null;
    status: string | null;
    operatedBy: string | null;
    operatedById: string | null;
    tankOwner: string | null;
    tankOwnerId: string | null;
    phoneNumber: string | null;
    tankNumber: string | null;
    tankId: string | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    needByTime: number | null;
    serviceType: string | null;
    serviceTypeId: string | null;
    serviceTypeOntraxId: string | null;
    foodGrade: boolean | null;
    kosher: boolean | null;
    specialInstructions: string | null;
    tankType: string | null;
    tankTypeId: string | null;
    compartmentsQty: number | null;
    lastContainedProductComp1Id: string | null;
    lastContainedProductComp2Id: string | null;
    lastContainedProductComp3Id: string | null;
    lastContainedProductComp4Id: string | null;
    lastContainedProductComp5Id: string | null;
    lastContainedProductComp1Name: string | null;
    lastContainedProductComp2Name: string | null;
    lastContainedProductComp3Name: string | null;
    lastContainedProductComp4Name: string | null;
    lastContainedProductComp5Name: string | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    serviceTypeComp1: string | null;
    serviceTypeComp2: string | null;
    serviceTypeComp3: string | null;
    serviceTypeComp4: string | null;
    serviceTypeComp5: string | null;
    specialInstructionsComp1: string | null;
    specialInstructionsComp2: string | null;
    specialInstructionsComp3: string | null;
    specialInstructionsComp4: string | null;
    specialInstructionsComp5: string | null;
    lastContainedProduct1Id: string | null;
    lastContainedProduct2Id: string | null;
    lastContainedProduct3Id: string | null;
    lastContainedProduct1Name: string | null;
    lastContainedProduct2Name: string | null;
    lastContainedProduct3Name: string | null;
    nitrogen: boolean | null;
    tractorNumber: string | null;
    pumpQty: number | null;
    hosesQty: number | null;
    fittingsQty: number | null;
    ladingBill: string | null;
    poNumber: string | null;
    washBillToTerminal: string | null;
    washBillToTerminalId: string | null;
    completeTime: number | null;
    startTime: number | null;
    containerArrived: boolean | null;
    containerPickedUp: boolean | null;
    pendingHeelApproval: boolean | null;
    flareRequired: boolean | null;
    files: string | null;
    allowedActions: string | null;
    lastContained1: string | null;
    lastContained2: string | null;
    lastContained3: string | null;
    expectedCleaningStart: number | null;
    exceptionType: string | null;
    wasteType: string | null;
    heelAmount: string | null;
    heelCost: string | null;
    laborHours: string | null;
    laborCost: string | null;
    steam: string | null;
    steamCost: string | null;
    isCod: boolean | null;
    specialPrep: boolean | null;
    needMoreInfo: boolean | null;
    temperature: string | null;
    poNumberForPrep: string | null;
    createdByBulkUpload: boolean | null;
    rejectionReason: string | null;
    rejectionReasonCode: string | null;
    servicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    recommendedServicePlan: {
      __typename: 'ServicePlan';
      servicePlanName: string | null;
    } | null;
    wasServicePlanChanged: boolean | null;
    reasonOfChange: string | null;
    reasonOfChangeCode: string | null;
    customerComments: string | null;
    isHeelPreApproved: boolean | null;
    workOrder: string | null;
    workOrderId: string | null;
    createdByEtendo: boolean | null;
    confinedEntry: boolean | null;
    confinedEntryType: string | null;
    workPerformedBy: {
      __typename: 'WorkPerformedBy';
      id: string | null;
      confinedEntryOperatorName: string | null;
    } | null;
    washRequestIdOpenBravo: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnMaintenanceChangesSubscription = {
  __typename: 'MaintenanceStatus';
  id: string;
  hasMaintenance: boolean | null;
};

export type OnRecentOperatorsChangesSubscription = {
  __typename: 'RecentOperators';
  terminalId: string | null;
  users: Array<string | null> | null;
};

export type OnAgendaChangesSubscription = {
  __typename: 'AgendaCards';
  terminalId: string | null;
  cards: Array<{
    __typename: 'LinkedScheduledRequest';
    id: string | null;
    bayId: string | null;
    bayName: string | null;
    compatibleBays: Array<string | null> | null;
    incompatibleBays: Array<{
      __typename: 'IncompatibleBay';
      bayId: string | null;
      failedValidations: Array<string | null> | null;
    } | null> | null;
    containerNumber: string | null;
    serviceType: string | null;
    status: string | null;
    duration: number | null;
    cardType: string | null;
    startTime: number | null;
    finishTime: number | null;
    needByTime: number | null;
    trailerArrivalTime: number | null;
    arrivalTime: number | null;
    flagMissingNeedByTime: boolean | null;
    flagBeforeArriving: boolean | null;
    flagAlmostOnNeedByTime: boolean | null;
    flagMissingVelocitySLA: boolean | null;
    availableActionsStart: boolean | null;
    availableActionsStop: boolean | null;
    availableActionsPause: boolean | null;
    availableActionsHold: boolean | null;
    availableActionsMove: boolean | null;
    terminal: string | null;
    terminalId: string | null;
    lastContained: string | null;
    workOrderCreated: boolean | null;
    reason: string | null;
    specialInstructions: string | null;
    linkedCardId: string | null;
    linkType: string | null;
    linkedCardType: string | null;
    linked: {
      __typename: 'ScheduledRequest';
      id: string | null;
      bayId: string | null;
      bayName: string | null;
      compatibleBays: Array<string | null> | null;
      incompatibleBays: Array<{
        __typename: 'IncompatibleBay';
        bayId: string | null;
        failedValidations: Array<string | null> | null;
      } | null> | null;
      containerNumber: string | null;
      serviceType: string | null;
      status: string | null;
      duration: number | null;
      cardType: string | null;
      startTime: number | null;
      finishTime: number | null;
      needByTime: number | null;
      trailerArrivalTime: number | null;
      arrivalTime: number | null;
      flagMissingNeedByTime: boolean | null;
      flagBeforeArriving: boolean | null;
      flagAlmostOnNeedByTime: boolean | null;
      flagMissingVelocitySLA: boolean | null;
      availableActionsStart: boolean | null;
      availableActionsStop: boolean | null;
      availableActionsPause: boolean | null;
      availableActionsHold: boolean | null;
      availableActionsMove: boolean | null;
      terminal: string | null;
      terminalId: string | null;
      lastContained: string | null;
      workOrderCreated: boolean | null;
      reason: string | null;
      specialInstructions: string | null;
      linkedCardId: string | null;
      linkType: string | null;
      linkedCardType: string | null;
      flareRequired: boolean | null;
      isOutOfShift: boolean | null;
      isATwoDaysSchedule: boolean | null;
      exteriorWash: boolean | null;
      isHeelPreApproved: boolean | null;
      operatedById: string | null;
      tankOwnerId: string | null;
      createdByEtendo: boolean | null;
      workOrderId: string | null;
      ectCustomerHasViewed: boolean | null;
      foodGrade: boolean | null;
      containsNitrogenComp1: boolean | null;
      containsNitrogenComp2: boolean | null;
      containsNitrogenComp3: boolean | null;
      containsNitrogenComp4: boolean | null;
      containsNitrogenComp5: boolean | null;
      nitrogen: boolean | null;
    } | null;
    flareRequired: boolean | null;
    isOutOfShift: boolean | null;
    isATwoDaysSchedule: boolean | null;
    exteriorWash: boolean | null;
    isHeelPreApproved: boolean | null;
    operatedById: string | null;
    tankOwnerId: string | null;
    createdByEtendo: boolean | null;
    workOrderId: string | null;
    ectCustomerHasViewed: boolean | null;
    foodGrade: boolean | null;
    containsNitrogenComp1: boolean | null;
    containsNitrogenComp2: boolean | null;
    containsNitrogenComp3: boolean | null;
    containsNitrogenComp4: boolean | null;
    containsNitrogenComp5: boolean | null;
    nitrogen: boolean | null;
  } | null> | null;
};

export type OnOrderConversionStatusChangesSubscription = {
  __typename: 'ConvertingRequests';
  terminalId: string | null;
  requests: Array<{
    __typename: 'RequestsConversionStatus';
    requestId: string | null;
    conversionStatus: ConversionStatuses | null;
    conversionMessage: string | null;
  } | null> | null;
};

export type OnSchneiderCompletionStatusChangesSubscription = {
  __typename: 'ScheneiderCompletionRequest';
  terminalId: string | null;
  requests: Array<{
    __typename: 'RequestsSchneiderCompletionStatus';
    requestId: string | null;
    completionStatus: SchneiderCompletionStatus | null;
    completionMessage: string | null;
  } | null> | null;
};

export type OnShiftChangesSubscription = {
  __typename: 'TerminalId';
  terminalId: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  async NotifyMaintenanceStatus(
    id?: string,
    hasMaintenance?: boolean
  ): Promise<NotifyMaintenanceStatusMutation> {
    const statement = `mutation NotifyMaintenanceStatus($id: String, $hasMaintenance: Boolean) {
        notifyMaintenanceStatus(id: $id, hasMaintenance: $hasMaintenance) {
          __typename
          id
          hasMaintenance
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (hasMaintenance) {
      gqlAPIServiceArguments.hasMaintenance = hasMaintenance;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyMaintenanceStatusMutation>(
      response.data.notifyMaintenanceStatus
    );
  }
  async NotifyRecentOperators(
    terminalId?: string,
    users?: Array<string | null>
  ): Promise<NotifyRecentOperatorsMutation> {
    const statement = `mutation NotifyRecentOperators($terminalId: String, $users: [String]) {
        notifyRecentOperators(terminalId: $terminalId, users: $users) {
          __typename
          terminalId
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    if (users) {
      gqlAPIServiceArguments.users = users;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyRecentOperatorsMutation>response.data.notifyRecentOperators;
  }
  async NotifyAgendaChanges(
    cards?: Array<LinkedUpdateCardsInput | null>,
    terminalId?: string
  ): Promise<NotifyAgendaChangesMutation> {
    const statement = `mutation NotifyAgendaChanges($cards: [LinkedUpdateCardsInput], $terminalId: String) {
        notifyAgendaChanges(cards: $cards, terminalId: $terminalId) {
          __typename
          terminalId
          cards {
            __typename
            id
            bayId
            bayName
            compatibleBays
            incompatibleBays {
              __typename
              bayId
              failedValidations
            }
            containerNumber
            serviceType
            status
            duration
            cardType
            startTime
            finishTime
            needByTime
            trailerArrivalTime
            arrivalTime
            flagMissingNeedByTime
            flagBeforeArriving
            flagAlmostOnNeedByTime
            flagMissingVelocitySLA
            availableActionsStart
            availableActionsStop
            availableActionsPause
            availableActionsHold
            availableActionsMove
            terminal
            terminalId
            lastContained
            workOrderCreated
            reason
            specialInstructions
            linkedCardId
            linkType
            linkedCardType
            linked {
              __typename
              id
              bayId
              bayName
              compatibleBays
              incompatibleBays {
                __typename
                bayId
                failedValidations
              }
              containerNumber
              serviceType
              status
              duration
              cardType
              startTime
              finishTime
              needByTime
              trailerArrivalTime
              arrivalTime
              flagMissingNeedByTime
              flagBeforeArriving
              flagAlmostOnNeedByTime
              flagMissingVelocitySLA
              availableActionsStart
              availableActionsStop
              availableActionsPause
              availableActionsHold
              availableActionsMove
              terminal
              terminalId
              lastContained
              workOrderCreated
              reason
              specialInstructions
              linkedCardId
              linkType
              linkedCardType
              flareRequired
              isOutOfShift
              isATwoDaysSchedule
              exteriorWash
              isHeelPreApproved
              operatedById
              tankOwnerId
              createdByEtendo
              workOrderId
              ectCustomerHasViewed
              foodGrade
              containsNitrogenComp1
              containsNitrogenComp2
              containsNitrogenComp3
              containsNitrogenComp4
              containsNitrogenComp5
              nitrogen
            }
            flareRequired
            isOutOfShift
            isATwoDaysSchedule
            exteriorWash
            isHeelPreApproved
            operatedById
            tankOwnerId
            createdByEtendo
            workOrderId
            ectCustomerHasViewed
            foodGrade
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            nitrogen
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cards) {
      gqlAPIServiceArguments.cards = cards;
    }
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyAgendaChangesMutation>response.data.notifyAgendaChanges;
  }
  async NotifyOrderConversionStatus(
    requests?: Array<RequestsConversionStatusInput | null>,
    terminalId?: string
  ): Promise<NotifyOrderConversionStatusMutation> {
    const statement = `mutation NotifyOrderConversionStatus($requests: [RequestsConversionStatusInput], $terminalId: String) {
        notifyOrderConversionStatus(requests: $requests, terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            conversionStatus
            conversionMessage
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (requests) {
      gqlAPIServiceArguments.requests = requests;
    }
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyOrderConversionStatusMutation>(
      response.data.notifyOrderConversionStatus
    );
  }
  async NotifySchneiderCompletionStatus(
    requests?: Array<RequestsCompletionStatusInput | null>,
    terminalId?: string
  ): Promise<NotifySchneiderCompletionStatusMutation> {
    const statement = `mutation NotifySchneiderCompletionStatus($requests: [RequestsCompletionStatusInput], $terminalId: String) {
        notifySchneiderCompletionStatus(requests: $requests, terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            completionStatus
            completionMessage
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (requests) {
      gqlAPIServiceArguments.requests = requests;
    }
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifySchneiderCompletionStatusMutation>(
      response.data.notifySchneiderCompletionStatus
    );
  }
  async NotifyShiftChanges(
    terminalId?: string
  ): Promise<NotifyShiftChangesMutation> {
    const statement = `mutation NotifyShiftChanges($terminalId: String) {
        notifyShiftChanges(terminalId: $terminalId) {
          __typename
          terminalId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyShiftChangesMutation>response.data.notifyShiftChanges;
  }
  async GetRecentOperators(
    terminalId?: string
  ): Promise<GetRecentOperatorsQuery> {
    const statement = `query GetRecentOperators($terminalId: String) {
        getRecentOperators(terminalId: $terminalId) {
          __typename
          terminalId
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminalId) {
      gqlAPIServiceArguments.terminalId = terminalId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRecentOperatorsQuery>response.data.getRecentOperators;
  }
  async ListWashRequest(nextToken?: string): Promise<ListWashRequestQuery> {
    const statement = `query ListWashRequest($nextToken: String) {
        listWashRequest(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWashRequestQuery>response.data.listWashRequest;
  }
  async GetWashRequestById(id?: string): Promise<GetWashRequestByIdQuery> {
    const statement = `query GetWashRequestById($id: String) {
        getWashRequestById(id: $id) {
          __typename
          id
          createdByRole
          createdBy
          lastUpdateTime
          lastUpdatedBy
          terminal
          terminalId
          status
          operatedBy
          operatedById
          tankOwner
          tankOwnerId
          phoneNumber
          tankNumber
          tankId
          trailerArrivalTime
          arrivalTime
          needByTime
          serviceType
          serviceTypeId
          serviceTypeOntraxId
          foodGrade
          kosher
          specialInstructions
          tankType
          tankTypeId
          compartmentsQty
          lastContainedProductComp1Id
          lastContainedProductComp2Id
          lastContainedProductComp3Id
          lastContainedProductComp4Id
          lastContainedProductComp5Id
          lastContainedProductComp1Name
          lastContainedProductComp2Name
          lastContainedProductComp3Name
          lastContainedProductComp4Name
          lastContainedProductComp5Name
          containsNitrogenComp1
          containsNitrogenComp2
          containsNitrogenComp3
          containsNitrogenComp4
          containsNitrogenComp5
          serviceTypeComp1
          serviceTypeComp2
          serviceTypeComp3
          serviceTypeComp4
          serviceTypeComp5
          specialInstructionsComp1
          specialInstructionsComp2
          specialInstructionsComp3
          specialInstructionsComp4
          specialInstructionsComp5
          lastContainedProduct1Id
          lastContainedProduct2Id
          lastContainedProduct3Id
          lastContainedProduct1Name
          lastContainedProduct2Name
          lastContainedProduct3Name
          nitrogen
          tractorNumber
          pumpQty
          hosesQty
          fittingsQty
          ladingBill
          poNumber
          washBillToTerminal
          washBillToTerminalId
          completeTime
          startTime
          containerArrived
          containerPickedUp
          pendingHeelApproval
          flareRequired
          files
          allowedActions
          lastContained1
          lastContained2
          lastContained3
          expectedCleaningStart
          exceptionType
          wasteType
          heelAmount
          heelCost
          laborHours
          laborCost
          steam
          steamCost
          isCod
          specialPrep
          needMoreInfo
          temperature
          poNumberForPrep
          createdByBulkUpload
          rejectionReason
          rejectionReasonCode
          servicePlan {
            __typename
            servicePlanName
          }
          recommendedServicePlan {
            __typename
            servicePlanName
          }
          wasServicePlanChanged
          reasonOfChange
          reasonOfChangeCode
          customerComments
          isHeelPreApproved
          workOrder
          workOrderId
          createdByEtendo
          confinedEntry
          confinedEntryType
          workPerformedBy {
            __typename
            id
            confinedEntryOperatorName
          }
          washRequestIdOpenBravo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWashRequestByIdQuery>response.data.getWashRequestById;
  }
  async GetBusinessPartnerNotificationPreferencesById(
    businessPartnerId?: string
  ): Promise<GetBusinessPartnerNotificationPreferencesByIdQuery> {
    const statement = `query GetBusinessPartnerNotificationPreferencesById($businessPartnerId: String) {
        getBusinessPartnerNotificationPreferencesById(businessPartnerId: $businessPartnerId) {
          __typename
          businessPartnerId
          bulkUploadSuccess
          bulkUploadNeedAdjustment
          bulkUploadFailedParseEmailRecipient
          bulkUploadFailedGetWarehouse
          BulkUploadCouldNotProcessEmail
          bulkUploadWrongFormat
          requestAcceptedByQuala
          requestRejected
          requestCompleted
          requestUpdated
          requestNeedHeelApproval
          requestHeelMoreInfoProvided
          servicePlanNotAvailable
          outsideWorkingHours
          exteriorWashOffer
          exteriorWashResponse
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartnerId) {
      gqlAPIServiceArguments.businessPartnerId = businessPartnerId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBusinessPartnerNotificationPreferencesByIdQuery>(
      response.data.getBusinessPartnerNotificationPreferencesById
    );
  }
  async GetUserNotificationPreferencesById(
    aduserId?: string
  ): Promise<GetUserNotificationPreferencesByIdQuery> {
    const statement = `query GetUserNotificationPreferencesById($aduserId: String) {
        getUserNotificationPreferencesById(aduserId: $aduserId) {
          __typename
          aduserId
          forwardUsersIds
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (aduserId) {
      gqlAPIServiceArguments.aduserId = aduserId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserNotificationPreferencesByIdQuery>(
      response.data.getUserNotificationPreferencesById
    );
  }
  async ListBusinessPartner(
    nextToken?: string
  ): Promise<ListBusinessPartnerQuery> {
    const statement = `query ListBusinessPartner($nextToken: String) {
        listBusinessPartner(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            contmngPOFormat
            contmngPOFormathelp
            customerBlocking
            contmngCustomernotes
            contmngCustomernotesParsed
            customer
            summaryLevel
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBusinessPartnerQuery>response.data.listBusinessPartner;
  }
  async ListTypeOfService(): Promise<Array<ListTypeOfServiceQuery>> {
    const statement = `query ListTypeOfService {
        listTypeOfService {
          __typename
          id
          name
          obId
          displayName
          key
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListTypeOfServiceQuery>>response.data.listTypeOfService;
  }
  async ListTypeOfTank(): Promise<Array<ListTypeOfTankQuery>> {
    const statement = `query ListTypeOfTank {
        listTypeOfTank {
          __typename
          id
          name
          aecdoFoodgradectrtypeName
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListTypeOfTankQuery>>response.data.listTypeOfTank;
  }
  async ListCustomerProductTypes(
    nextToken?: string
  ): Promise<ListCustomerProductTypesQuery> {
    const statement = `query ListCustomerProductTypes($nextToken: String) {
        listCustomerProductTypes(nextToken: $nextToken) {
          __typename
          items {
            __typename
            containerTypeId
            containerTypeName
            productId
            productName
            productCategoryName
            containerCategoryName
            customerProductType
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerProductTypesQuery>(
      response.data.listCustomerProductTypes
    );
  }
  async GetBillToTerminal(
    businessPartnerId?: string,
    nextToken?: string
  ): Promise<GetBillToTerminalQuery> {
    const statement = `query GetBillToTerminal($businessPartnerId: String, $nextToken: String) {
        getBillToTerminal(businessPartnerId: $businessPartnerId, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            businessPartnerId
            businessPartnerName
            locationAddressId
            locationAddressName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartnerId) {
      gqlAPIServiceArguments.businessPartnerId = businessPartnerId;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBillToTerminalQuery>response.data.getBillToTerminal;
  }
  async GetTank(
    businessPartnerId?: string,
    containerTypeId?: string,
    nextToken?: string
  ): Promise<GetTankQuery> {
    const statement = `query GetTank($businessPartnerId: String, $containerTypeId: String, $nextToken: String) {
        getTank(businessPartnerId: $businessPartnerId, containerTypeId: $containerTypeId, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            businessPartnerId
            businessPartnerName
            compartments
            containerTypeId
            containerTypeName
            operatedById
            operatedByName
            searchKey
            warehouseId
            warehouseName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartnerId) {
      gqlAPIServiceArguments.businessPartnerId = businessPartnerId;
    }
    if (containerTypeId) {
      gqlAPIServiceArguments.containerTypeId = containerTypeId;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTankQuery>response.data.getTank;
  }
  async ListProductContent(
    nextToken?: string
  ): Promise<ListProductContentQuery> {
    const statement = `query ListProductContent($nextToken: String) {
        listProductContent(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            searchKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductContentQuery>response.data.listProductContent;
  }
  async GetActiveWarehouses(
    nextToken?: string
  ): Promise<GetActiveWarehousesQuery> {
    const statement = `query GetActiveWarehouses($nextToken: String) {
        getActiveWarehouses(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            active
            searchKey
            terminalName
            terminalNameDisplay
            terminalNumber
            terminalNumberDisplay
            terminalLaunch
            operationalRegion
            linesOfBusiness
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetActiveWarehousesQuery>response.data.getActiveWarehouses;
  }
  async GetAllWarehouses(nextToken?: string): Promise<GetAllWarehousesQuery> {
    const statement = `query GetAllWarehouses($nextToken: String) {
        getAllWarehouses(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            active
            searchKey
            terminalName
            terminalNameDisplay
            terminalNumber
            terminalNumberDisplay
            terminalLaunch
            operationalRegion
            linesOfBusiness
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAllWarehousesQuery>response.data.getAllWarehouses;
  }
  async ListRejectionReason(
    nextToken?: string
  ): Promise<ListRejectionReasonQuery> {
    const statement = `query ListRejectionReason($nextToken: String) {
        listRejectionReason(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            code
            displayName
            isVisible
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRejectionReasonQuery>response.data.listRejectionReason;
  }
  async ListReasonOfChange(
    nextToken?: string
  ): Promise<ListReasonOfChangeQuery> {
    const statement = `query ListReasonOfChange($nextToken: String) {
        listReasonOfChange(nextToken: $nextToken) {
          __typename
          items {
            __typename
            code
            displayName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReasonOfChangeQuery>response.data.listReasonOfChange;
  }
  async ListMissedNeedByTimeReason(
    nextToken?: string
  ): Promise<ListMissedNeedByTimeReasonQuery> {
    const statement = `query ListMissedNeedByTimeReason($nextToken: String) {
        listMissedNeedByTimeReason(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMissedNeedByTimeReasonQuery>(
      response.data.listMissedNeedByTimeReason
    );
  }
  async ListNcrCorrectiveActionCategory(
    nextToken?: string
  ): Promise<ListNcrCorrectiveActionCategoryQuery> {
    const statement = `query ListNcrCorrectiveActionCategory($nextToken: String) {
        listNcrCorrectiveActionCategory(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrCorrectiveActionCategoryQuery>(
      response.data.listNcrCorrectiveActionCategory
    );
  }
  async ListNcrIncidentCategory(
    nextToken?: string
  ): Promise<ListNcrIncidentCategoryQuery> {
    const statement = `query ListNcrIncidentCategory($nextToken: String) {
        listNcrIncidentCategory(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrIncidentCategoryQuery>response.data.listNcrIncidentCategory;
  }
  async ListNcrIncidentResponsibility(
    nextToken?: string
  ): Promise<ListNcrIncidentResponsibilityQuery> {
    const statement = `query ListNcrIncidentResponsibility($nextToken: String) {
        listNcrIncidentResponsibility(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrIncidentResponsibilityQuery>(
      response.data.listNcrIncidentResponsibility
    );
  }
  async ListNcrShiftStartTimes(
    nextToken?: string
  ): Promise<ListNcrShiftStartTimesQuery> {
    const statement = `query ListNcrShiftStartTimes($nextToken: String) {
        listNcrShiftStartTimes(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            displayName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrShiftStartTimesQuery>response.data.listNcrShiftStartTimes;
  }
  async ListNcrRemedialTrainingProvided(
    nextToken?: string
  ): Promise<ListNcrRemedialTrainingProvidedQuery> {
    const statement = `query ListNcrRemedialTrainingProvided($nextToken: String) {
        listNcrRemedialTrainingProvided(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrRemedialTrainingProvidedQuery>(
      response.data.listNcrRemedialTrainingProvided
    );
  }
  async ListNcrIssueType(nextToken?: string): Promise<ListNcrIssueTypeQuery> {
    const statement = `query ListNcrIssueType($nextToken: String) {
        listNcrIssueType(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
            possibleContaminationOrDamage
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrIssueTypeQuery>response.data.listNcrIssueType;
  }
  async ListNcrSignedStatement(
    nextToken?: string
  ): Promise<ListNcrSignedStatementQuery> {
    const statement = `query ListNcrSignedStatement($nextToken: String) {
        listNcrSignedStatement(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrSignedStatementQuery>response.data.listNcrSignedStatement;
  }
  async ListNcrWhoHaveBeenNotified(
    nextToken?: string
  ): Promise<ListNcrWhoHaveBeenNotifiedQuery> {
    const statement = `query ListNcrWhoHaveBeenNotified($nextToken: String) {
        listNcrWhoHaveBeenNotified(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNcrWhoHaveBeenNotifiedQuery>(
      response.data.listNcrWhoHaveBeenNotified
    );
  }
  async GetDraftByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetDraftByTerminalQuery> {
    const statement = `query GetDraftByTerminal($terminal: String, $nextToken: String) {
        getDraftByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDraftByTerminalQuery>response.data.getDraftByTerminal;
  }
  async GetNewRequestsByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetNewRequestsByTerminalQuery> {
    const statement = `query GetNewRequestsByTerminal($terminal: String, $nextToken: String) {
        getNewRequestsByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNewRequestsByTerminalQuery>(
      response.data.getNewRequestsByTerminal
    );
  }
  async GetNeedsActionByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetNeedsActionByTerminalQuery> {
    const statement = `query GetNeedsActionByTerminal($terminal: String, $nextToken: String) {
        getNeedsActionByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNeedsActionByTerminalQuery>(
      response.data.getNeedsActionByTerminal
    );
  }
  async GetPendingByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetPendingByTerminalQuery> {
    const statement = `query GetPendingByTerminal($terminal: String, $nextToken: String) {
        getPendingByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPendingByTerminalQuery>response.data.getPendingByTerminal;
  }
  async GetOnHoldByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetOnHoldByTerminalQuery> {
    const statement = `query GetOnHoldByTerminal($terminal: String, $nextToken: String) {
        getOnHoldByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOnHoldByTerminalQuery>response.data.getOnHoldByTerminal;
  }
  async GetAcceptedByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetAcceptedByTerminalQuery> {
    const statement = `query GetAcceptedByTerminal($terminal: String, $nextToken: String) {
        getAcceptedByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAcceptedByTerminalQuery>response.data.getAcceptedByTerminal;
  }
  async GetInProgressByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetInProgressByTerminalQuery> {
    const statement = `query GetInProgressByTerminal($terminal: String, $nextToken: String) {
        getInProgressByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInProgressByTerminalQuery>response.data.getInProgressByTerminal;
  }
  async GetCompletedByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetCompletedByTerminalQuery> {
    const statement = `query GetCompletedByTerminal($terminal: String, $nextToken: String) {
        getCompletedByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCompletedByTerminalQuery>response.data.getCompletedByTerminal;
  }
  async GetCanceledByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetCanceledByTerminalQuery> {
    const statement = `query GetCanceledByTerminal($terminal: String, $nextToken: String) {
        getCanceledByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCanceledByTerminalQuery>response.data.getCanceledByTerminal;
  }
  async GetRejectedByTerminal(
    terminal?: string,
    nextToken?: string
  ): Promise<GetRejectedByTerminalQuery> {
    const statement = `query GetRejectedByTerminal($terminal: String, $nextToken: String) {
        getRejectedByTerminal(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRejectedByTerminalQuery>response.data.getRejectedByTerminal;
  }
  async GetDraftByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetDraftByBusinessPartnerQuery> {
    const statement = `query GetDraftByBusinessPartner($businessPartner: String, $nextToken: String) {
        getDraftByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDraftByBusinessPartnerQuery>(
      response.data.getDraftByBusinessPartner
    );
  }
  async GetCreationByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetCreationByBusinessPartnerQuery> {
    const statement = `query GetCreationByBusinessPartner($businessPartner: String, $nextToken: String) {
        getCreationByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCreationByBusinessPartnerQuery>(
      response.data.getCreationByBusinessPartner
    );
  }
  async GetSubmittedByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetSubmittedByBusinessPartnerQuery> {
    const statement = `query GetSubmittedByBusinessPartner($businessPartner: String, $nextToken: String) {
        getSubmittedByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubmittedByBusinessPartnerQuery>(
      response.data.getSubmittedByBusinessPartner
    );
  }
  async GetNeedsActionByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetNeedsActionByBusinessPartnerQuery> {
    const statement = `query GetNeedsActionByBusinessPartner($businessPartner: String, $nextToken: String) {
        getNeedsActionByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNeedsActionByBusinessPartnerQuery>(
      response.data.getNeedsActionByBusinessPartner
    );
  }
  async GetAcceptedByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetAcceptedByBusinessPartnerQuery> {
    const statement = `query GetAcceptedByBusinessPartner($businessPartner: String, $nextToken: String) {
        getAcceptedByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAcceptedByBusinessPartnerQuery>(
      response.data.getAcceptedByBusinessPartner
    );
  }
  async GetInProgressByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetInProgressByBusinessPartnerQuery> {
    const statement = `query GetInProgressByBusinessPartner($businessPartner: String, $nextToken: String) {
        getInProgressByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInProgressByBusinessPartnerQuery>(
      response.data.getInProgressByBusinessPartner
    );
  }
  async GetCompletedByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetCompletedByBusinessPartnerQuery> {
    const statement = `query GetCompletedByBusinessPartner($businessPartner: String, $nextToken: String) {
        getCompletedByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCompletedByBusinessPartnerQuery>(
      response.data.getCompletedByBusinessPartner
    );
  }
  async GetCanceledByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetCanceledByBusinessPartnerQuery> {
    const statement = `query GetCanceledByBusinessPartner($businessPartner: String, $nextToken: String) {
        getCanceledByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCanceledByBusinessPartnerQuery>(
      response.data.getCanceledByBusinessPartner
    );
  }
  async GetRejectedByBusinessPartner(
    businessPartner?: string,
    nextToken?: string
  ): Promise<GetRejectedByBusinessPartnerQuery> {
    const statement = `query GetRejectedByBusinessPartner($businessPartner: String, $nextToken: String) {
        getRejectedByBusinessPartner(businessPartner: $businessPartner, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessPartner) {
      gqlAPIServiceArguments.businessPartner = businessPartner;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRejectedByBusinessPartnerQuery>(
      response.data.getRejectedByBusinessPartner
    );
  }
  async GetOperators(
    terminal?: string,
    nextToken?: string
  ): Promise<GetOperatorsQuery> {
    const statement = `query GetOperators($terminal: String, $nextToken: String) {
        getOperators(terminal: $terminal, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userContactId
            roleId
            roleName
            userContactName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (terminal) {
      gqlAPIServiceArguments.terminal = terminal;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOperatorsQuery>response.data.getOperators;
  }
  async GetExistingWashRequests(
    tankNumber?: string,
    operatedBy?: string,
    lastUpdateTime?: number
  ): Promise<GetExistingWashRequestsQuery> {
    const statement = `query GetExistingWashRequests($tankNumber: String, $operatedBy: String, $lastUpdateTime: Int) {
        getExistingWashRequests(tankNumber: $tankNumber, operatedBy: $operatedBy, lastUpdateTime: $lastUpdateTime) {
          __typename
          items {
            __typename
            id
            createdByRole
            createdBy
            lastUpdateTime
            lastUpdatedBy
            terminal
            terminalId
            status
            operatedBy
            operatedById
            tankOwner
            tankOwnerId
            phoneNumber
            tankNumber
            tankId
            trailerArrivalTime
            arrivalTime
            needByTime
            serviceType
            serviceTypeId
            serviceTypeOntraxId
            foodGrade
            kosher
            specialInstructions
            tankType
            tankTypeId
            compartmentsQty
            lastContainedProductComp1Id
            lastContainedProductComp2Id
            lastContainedProductComp3Id
            lastContainedProductComp4Id
            lastContainedProductComp5Id
            lastContainedProductComp1Name
            lastContainedProductComp2Name
            lastContainedProductComp3Name
            lastContainedProductComp4Name
            lastContainedProductComp5Name
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            serviceTypeComp1
            serviceTypeComp2
            serviceTypeComp3
            serviceTypeComp4
            serviceTypeComp5
            specialInstructionsComp1
            specialInstructionsComp2
            specialInstructionsComp3
            specialInstructionsComp4
            specialInstructionsComp5
            lastContainedProduct1Id
            lastContainedProduct2Id
            lastContainedProduct3Id
            lastContainedProduct1Name
            lastContainedProduct2Name
            lastContainedProduct3Name
            nitrogen
            tractorNumber
            pumpQty
            hosesQty
            fittingsQty
            ladingBill
            poNumber
            washBillToTerminal
            washBillToTerminalId
            completeTime
            startTime
            containerArrived
            containerPickedUp
            pendingHeelApproval
            flareRequired
            files
            allowedActions
            lastContained1
            lastContained2
            lastContained3
            expectedCleaningStart
            exceptionType
            wasteType
            heelAmount
            heelCost
            laborHours
            laborCost
            steam
            steamCost
            isCod
            specialPrep
            needMoreInfo
            temperature
            poNumberForPrep
            createdByBulkUpload
            rejectionReason
            rejectionReasonCode
            servicePlan {
              __typename
              servicePlanName
            }
            recommendedServicePlan {
              __typename
              servicePlanName
            }
            wasServicePlanChanged
            reasonOfChange
            reasonOfChangeCode
            customerComments
            isHeelPreApproved
            workOrder
            workOrderId
            createdByEtendo
            confinedEntry
            confinedEntryType
            workPerformedBy {
              __typename
              id
              confinedEntryOperatorName
            }
            washRequestIdOpenBravo
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tankNumber) {
      gqlAPIServiceArguments.tankNumber = tankNumber;
    }
    if (operatedBy) {
      gqlAPIServiceArguments.operatedBy = operatedBy;
    }
    if (lastUpdateTime) {
      gqlAPIServiceArguments.lastUpdateTime = lastUpdateTime;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExistingWashRequestsQuery>response.data.getExistingWashRequests;
  }
  OnMaintenanceChangesListener: Observable<
    SubscriptionResponse<OnMaintenanceChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnMaintenanceChanges {
        onMaintenanceChanges {
          __typename
          id
          hasMaintenance
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnMaintenanceChangesSubscription>>;

  OnRecentOperatorsChangesListener: Observable<
    SubscriptionResponse<OnRecentOperatorsChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnRecentOperatorsChanges($terminalId: String) {
        onRecentOperatorsChanges(terminalId: $terminalId) {
          __typename
          terminalId
          users
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnRecentOperatorsChangesSubscription>>;

  OnAgendaChangesListener: Observable<
    SubscriptionResponse<OnAgendaChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnAgendaChanges($terminalId: String) {
        onAgendaChanges(terminalId: $terminalId) {
          __typename
          terminalId
          cards {
            __typename
            id
            bayId
            bayName
            compatibleBays
            incompatibleBays {
              __typename
              bayId
              failedValidations
            }
            containerNumber
            serviceType
            status
            duration
            cardType
            startTime
            finishTime
            needByTime
            trailerArrivalTime
            arrivalTime
            flagMissingNeedByTime
            flagBeforeArriving
            flagAlmostOnNeedByTime
            flagMissingVelocitySLA
            availableActionsStart
            availableActionsStop
            availableActionsPause
            availableActionsHold
            availableActionsMove
            terminal
            terminalId
            lastContained
            workOrderCreated
            reason
            specialInstructions
            linkedCardId
            linkType
            linkedCardType
            linked {
              __typename
              id
              bayId
              bayName
              compatibleBays
              incompatibleBays {
                __typename
                bayId
                failedValidations
              }
              containerNumber
              serviceType
              status
              duration
              cardType
              startTime
              finishTime
              needByTime
              trailerArrivalTime
              arrivalTime
              flagMissingNeedByTime
              flagBeforeArriving
              flagAlmostOnNeedByTime
              flagMissingVelocitySLA
              availableActionsStart
              availableActionsStop
              availableActionsPause
              availableActionsHold
              availableActionsMove
              terminal
              terminalId
              lastContained
              workOrderCreated
              reason
              specialInstructions
              linkedCardId
              linkType
              linkedCardType
              flareRequired
              isOutOfShift
              isATwoDaysSchedule
              exteriorWash
              isHeelPreApproved
              operatedById
              tankOwnerId
              createdByEtendo
              workOrderId
              ectCustomerHasViewed
              foodGrade
              containsNitrogenComp1
              containsNitrogenComp2
              containsNitrogenComp3
              containsNitrogenComp4
              containsNitrogenComp5
              nitrogen
            }
            flareRequired
            isOutOfShift
            isATwoDaysSchedule
            exteriorWash
            isHeelPreApproved
            operatedById
            tankOwnerId
            createdByEtendo
            workOrderId
            ectCustomerHasViewed
            foodGrade
            containsNitrogenComp1
            containsNitrogenComp2
            containsNitrogenComp3
            containsNitrogenComp4
            containsNitrogenComp5
            nitrogen
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnAgendaChangesSubscription>>;

  OnOrderConversionStatusChangesListener: Observable<
    SubscriptionResponse<OnOrderConversionStatusChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnOrderConversionStatusChanges($terminalId: String) {
        onOrderConversionStatusChanges(terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            conversionStatus
            conversionMessage
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnOrderConversionStatusChangesSubscription>
  >;

  OnSchneiderCompletionStatusChangesListener: Observable<
    SubscriptionResponse<OnSchneiderCompletionStatusChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnSchneiderCompletionStatusChanges($terminalId: String) {
        onSchneiderCompletionStatusChanges(terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            completionStatus
            completionMessage
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnSchneiderCompletionStatusChangesSubscription>
  >;

  OnShiftChangesListener: Observable<
    SubscriptionResponse<OnShiftChangesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnShiftChanges($terminalId: String) {
        onShiftChanges(terminalId: $terminalId) {
          __typename
          terminalId
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnShiftChangesSubscription>>;
}
