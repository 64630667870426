import { Injectable } from '@angular/core';
import API, { graphqlOperation } from '@aws-amplify/api';
import { Observable } from 'zen-observable-ts';
import {
  SubscriptionResponse,
  OnAgendaChangesSubscription,
  OnOrderConversionStatusChangesSubscription,
  OnShiftChangesSubscription,
  OnSchneiderCompletionStatusChangesSubscription,
  OnRecentOperatorsChangesSubscription,
} from './API.service';

const onRecentsOperatorsChangesByTerminal = `subscription OnRecentOperatorsChanges($terminalId: String) {
  onRecentOperatorsChanges(terminalId: $terminalId) {
    __typename
    terminalId
    users
  }
}`;

const onAgendaChangesByTerminal = `subscription OnAgendaChanges($terminalId: String) {
  onAgendaChanges(terminalId: $terminalId) {
    __typename
    terminalId
    cards {
      __typename
      id
      bayId
      bayName
      compatibleBays,
      incompatibleBays {
        bayId
        failedValidations
      }
      containerNumber
      serviceType
      status
      duration
      cardType
      startTime
      finishTime
      needByTime
      arrivalTime
      trailerArrivalTime
      flagMissingNeedByTime
      flagBeforeArriving
      isATwoDaysSchedule
      flagAlmostOnNeedByTime
      flagMissingVelocitySLA
      availableActionsStart
      availableActionsStop
      availableActionsPause
      availableActionsHold
      availableActionsMove
      terminal
      terminalId
      lastContained
      workOrderCreated
      reason
      specialInstructions
      linkedCardId
      linkType
      linkedCardType
      exteriorWash
      isOutOfShift
      isATwoDaysSchedule
      createdByEtendo
      ectCustomerHasViewed
      linked {
        __typename
        id
        bayId
        bayName
        compatibleBays
        containerNumber
        serviceType
        status
        duration
        cardType
        startTime
        finishTime
        needByTime
        arrivalTime
        trailerArrivalTime
        flagMissingNeedByTime
        flagBeforeArriving
        flagAlmostOnNeedByTime
        flagMissingVelocitySLA
        availableActionsStart
        availableActionsStop
        availableActionsPause
        availableActionsHold
        availableActionsMove
        terminal
        terminalId
        lastContained
        workOrderCreated
        reason
        specialInstructions
        linkedCardId
        linkType
        linkedCardType
        flareRequired
        exteriorWash
        isOutOfShift
        isATwoDaysSchedule
        isHeelPreApproved
        createdByEtendo
        ectCustomerHasViewed
        foodGrade
        containsNitrogenComp1
        containsNitrogenComp2
        containsNitrogenComp3
        containsNitrogenComp4
        containsNitrogenComp5
        nitrogen
      }
      flareRequired
      isHeelPreApproved
      foodGrade
      containsNitrogenComp1
      containsNitrogenComp2
      containsNitrogenComp3
      containsNitrogenComp4
      containsNitrogenComp5
      nitrogen
    }
  }
}`;

const onOrderConversionStatusChangesByTerminal = `subscription OnOrderConversionStatusChanges($terminalId: String) {
        onOrderConversionStatusChanges(terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            conversionStatus
            conversionMessage
          }
        }
      }`;

const onSchneiderCompletionStatusChangesByTerminal = `subscription OnSchneiderCompletionStatusChanges($terminalId: String) {
        onSchneiderCompletionStatusChanges(terminalId: $terminalId) {
          __typename
          terminalId
          requests {
            __typename
            requestId
            completionStatus
            completionMessage
          }
        }
      }`;

const onShiftChangesByTerminal = `subscription OnShiftChanges($terminalId: String) {
  onShiftChanges(terminalId: $terminalId) {
    __typename
    terminalId
  }
}`;

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor() {}

  listenToRecentOperatorsChangesByTerminal(terminalId: string): Observable<
    SubscriptionResponse<{
      onRecentOperatorsChanges: OnRecentOperatorsChangesSubscription;
    }>
  > {
    const operation = graphqlOperation(onRecentsOperatorsChangesByTerminal, {
      terminalId,
    });
    return API.graphql(operation) as Observable<
      SubscriptionResponse<{
        onRecentOperatorsChanges: OnRecentOperatorsChangesSubscription;
      }>
    >;
  }

  listenToAgendaChangesByTerminal(
    terminalId: string
  ): Observable<
    SubscriptionResponse<{ onAgendaChanges: OnAgendaChangesSubscription }>
  > {
    const operation = graphqlOperation(onAgendaChangesByTerminal, {
      terminalId,
    });
    return API.graphql(operation) as Observable<
      SubscriptionResponse<{ onAgendaChanges: OnAgendaChangesSubscription }>
    >;
  }

  listenToOrderConversionStatusChangeByTerminal(terminalId: string): Observable<
    SubscriptionResponse<{
      onOrderConversionStatusChanges: OnOrderConversionStatusChangesSubscription;
    }>
  > {
    const operation = graphqlOperation(
      onOrderConversionStatusChangesByTerminal,
      { terminalId }
    );
    return API.graphql(operation) as Observable<
      SubscriptionResponse<{
        onOrderConversionStatusChanges: OnOrderConversionStatusChangesSubscription;
      }>
    >;
  }

  listenToSchneiderCompletionStatusChangeByTerminal(
    terminalId: string
  ): Observable<
    SubscriptionResponse<{
      onSchneiderCompletionStatusChanges: OnSchneiderCompletionStatusChangesSubscription;
    }>
  > {
    const operation = graphqlOperation(
      onSchneiderCompletionStatusChangesByTerminal,
      { terminalId }
    );
    return API.graphql(operation) as Observable<
      SubscriptionResponse<{
        onSchneiderCompletionStatusChanges: OnSchneiderCompletionStatusChangesSubscription;
      }>
    >;
  }

  listenToShiftChangesByTerminal(
    terminalId: string
  ): Observable<
    SubscriptionResponse<{ onShiftChanges: OnShiftChangesSubscription }>
  > {
    const operation = graphqlOperation(onShiftChangesByTerminal, {
      terminalId,
    });
    return API.graphql(operation) as Observable<
      SubscriptionResponse<{ onShiftChanges: OnShiftChangesSubscription }>
    >;
  }
}
