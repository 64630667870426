import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { DashboardService } from './dashboard.service';
import { AuthService } from '../core/auth/auth.service';
import { MenuConfig, MenuOption } from './menu/menu.model';
import { WashListService } from './wash-list/wash-list.service';
import { APIService } from '../core/API.service';
import { SystemService } from '../core/system.service';
import { User } from '../core/model/user.model';
import { LocalStorageService } from '../core/localstorage.service';
import { BusinessPartnerService } from '../core/services/business-partner.service';
import { WarehouseService } from '../core/services/warehouse.service';
import { MasterAccountService } from '../core/services/master-account.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('slideMenu', [
      state(
        'true',
        style({
          transform: 'none',
        })
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class DashboardComponent implements OnInit {
  static route = 'dashboard';
  public isVisible: boolean;
  private isTabActive = true;

  menuConfig: MenuConfig;
  isMobile: boolean;

  private user: User;
  userName: string;

  constructor(
    private dashboardService: DashboardService,
    private businessPartnerService: BusinessPartnerService,
    private washListService: WashListService,
    private warehouseService: WarehouseService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private appSyncService: APIService,
    private systemService: SystemService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private masterAccountService: MasterAccountService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.washListService.changeBreakpointFlag(!result.matches);
      this.isMobile = !result.matches;
    });
  }

  ngOnInit() {
    this.user = this.localStorageService.getUser();
    this.userName = this.user.username;
    this.menuConfig = this.dashboardService.getMenuConfig();
    this.businessPartnerService.loadBusinessPartners().catch((error) => {
      throw error;
    });
    this.masterAccountService.getMasterCustomer().catch((error) => {
      throw error;
    });
    this.washListService.loadServiceType().catch((error) => {
      throw error;
    });
    this.washListService.loadContainerType().catch((error) => {
      throw error;
    });
    this.washListService.loadProductContent().catch((error) => {
      throw error;
    });
    this.warehouseService.loadActiveWarehouses().catch((error) => {
      throw error;
    });
    this.warehouseService.loadAllWarehouses().catch((error) => {
      throw error;
    });
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    this.washListService.loadExteriorWashProducts().catch((error) => {
      throw error;
    });

    this.washListService.getReasonsOfChange().catch((error) => {
      throw error;
    });

    const customerId = this.authService.getBusinessPartnerId();
    if (customerId) {
      this.washListService.loadWashBillToTerminal(customerId);
    }

    this.appSyncService.OnMaintenanceChangesListener.subscribe((event: any) => {
      const maintenance = get(
        event,
        ['value', 'data', 'onMaintenanceChanges'],
        {}
      );
      this.systemService.setMaintenanceStatus(maintenance.hasMaintenance);
      if (maintenance.hasMaintenance) {
        this.router.navigate(['/system-maintenance']);
      }
    });

    if (this.user.customerBlocking) {
      this.toastr.warning(
        '',
        'Account on hold. Requests can be entered but will not be scheduled. Please contact your sales representative.'
      );
    }

    window.onblur = () => {
      this.isTabActive = false;
    };

    window.onfocus = () => {
      this.isTabActive = true;
    };

    this.authService.isAuthorized().then((isAuthenticated) => {
      if (isAuthenticated) {
        this.getAllWashItemsCountForUserRole();
        if (this.hasQualaRole) {
          setInterval(() => {
            this.getImportantItemsCountForQualaRole();
          }, 60000 * 3);
          setInterval(() => {
            this.getNormalItemsCountForQualaRole();
          }, 60000 * 8);
        } else {
          setInterval(() => {
            this.getImportantItemsCountForDispatcher();
          }, 60000 * 3);
          setInterval(() => {
            this.getNormalItemsCountForDispatcher();
          }, 60000 * 8);
        }
        setInterval(() => {
          this.getLowPriorityItemsCount();
        }, 60000 * 30);
      }
    });
  }

  public toggleMenu(): void {
    this.isVisible = !this.isVisible;
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  getAllWashItemsCountForUserRole() {
    if (this.hasQualaRole) {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.NewWashRequests,
        MenuOption.Pending,
        MenuOption.Accepted,
        MenuOption.NeedsAction,
        MenuOption.InProgress,
        MenuOption.Hold,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
        MenuOption.CreditHold,
        MenuOption.Expired,
        MenuOption.SchneiderPortalCompletions,
      ]);
    } else {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.Submitted,
        MenuOption.NeedsAction,
        MenuOption.Accepted,
        MenuOption.Pending,
        MenuOption.InProgress,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
      ]);
    }
  }

  getImportantItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NewWashRequests,
      MenuOption.Pending,
      MenuOption.NeedsAction,
    ]);
  }

  getNormalItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Accepted,
      MenuOption.InProgress,
      MenuOption.Hold,
      MenuOption.CreditHold,
    ]);
  }

  getImportantItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Submitted,
      MenuOption.Accepted,
    ]);
  }

  getNormalItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NeedsAction,
      MenuOption.Pending,
      MenuOption.InProgress,
    ]);
  }

  getLowPriorityItemsCount() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Draft,
      MenuOption.Completed,
      MenuOption.Canceled,
      MenuOption.Rejected,
    ]);
  }
}
