import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../core/localstorage.service';
import { User } from '../../core/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class InactiveTerminalGuard {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}
  private user: any;

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    this.user = this.localStorageService.getUser();

    const canLoad = this.user.currentTerminal.active;

    if (canLoad) {
      return true;
    }

    this.router.navigateByUrl('/');
    return false;
  }
}
