// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-component {
  align-items: center;
}
.home-component .welcome-message {
  text-align: center;
  margin-bottom: 1.25rem;
}
.home-component .welcome-message .welcome-text {
  font-weight: bold;
  font-size: 2.5rem;
  color: #006937;
}
.home-component .welcome-message .welcome-text.lighter-green {
  color: #75d01a;
}
.home-component .welcome-message .welcome-text.italic {
  font-style: italic;
}
.home-component .welcome-message .green-line {
  border: 0.25rem solid #26a532;
  width: 40%;
  margin: 0 auto;
}
.home-component .welcome-message .whats-new {
  font-weight: bold;
  font-size: 1.5rem;
  color: #75d01a;
  margin: 1.25rem 0;
}
.home-component .news-feed-container {
  margin: 5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/home/home.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAGE;EACE,kBAAA;EACA,sBAAA;AADJ;AAGI;EACE,iBAAA;EACA,iBAAA;EACA,cCKS;ADNf;AAGM;EACE,cAAA;AADR;AAIM;EACE,kBAAA;AAFR;AAMI;EACE,6BAAA;EACA,UAAA;EACA,cAAA;AAJN;AAOI;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AALN;AASE;EACE,cAAA;AAPJ","sourcesContent":["@import '../../../variables.scss';\n\n.home-component {\n  align-items: center;\n\n  .welcome-message {\n    text-align: center;\n    margin-bottom: 1.25rem;\n\n    .welcome-text {\n      font-weight: bold;\n      font-size: 2.5rem;\n      color: $jungle-green;\n\n      &.lighter-green {\n        color: #75d01a;\n      }\n\n      &.italic {\n        font-style: italic;\n      }\n    }\n\n    .green-line {\n      border: 0.25rem solid $forest-green;\n      width: 40%;\n      margin: 0 auto;\n    }\n\n    .whats-new {\n      font-weight: bold;\n      font-size: 1.5rem;\n      color: #75d01a;\n      margin: 1.25rem 0;\n    }\n  }\n\n  .news-feed-container {\n    margin: 5rem 0;\n  }\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
