import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { QuicksightDashboardComponent } from '../../quicksight/quicksight-dashboard.component';

@Component({
  selector: 'app-terminal-scorecard-depot',
  templateUrl: './terminal-scorecard-depot.component.html',
  styleUrls: ['../../quicksight/quicksight-dashboard.component.scss'],
})
export class TerminalScorecardDepotComponent
  extends QuicksightDashboardComponent
  implements OnInit
{
  constructor(
    private reportsService: ReportsService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reportsService
      .getDashboardByName('OPS - Terminal Scorecard - Depot & Transportation')
      .then((quicksightDashboardData) => {
        this.loadDashboardContainer(quicksightDashboardData, [
          {
            Name: 'terminalnumberparameter',
            Values: [this.authService.user.currentTerminal.number],
          },
        ]);
      });
  }
}
