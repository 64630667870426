import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../localstorage.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformActionsWashRequestGuard {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  async canLoadOrActivate() {
    const user = this.localStorageService.getUser();

    const canLoad =
      (user.currentTerminal &&
        user.currentTerminal.platformActions &&
        user.currentTerminal.platformActions.washSchedule) ||
      false;
    if (canLoad) {
      return true;
    }
    this.router.navigateByUrl('/dashboard/home');
    return false;
  }
}
