import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class DepotRoleGuard {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate();
  }

  canLoadOrActivate() {
    const user = this.localStorageService.getUser();
    const canLoad = !user.currentRole.toLowerCase().includes('depot');
    if (canLoad) {
      return true;
    }
    this.router.navigateByUrl('/dashboard/home');
    return false;
  }
}
