import { AuthService } from '../../../core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogConfirmationService } from '../../../shared/dialog-confirmation/dialog-confirmation.service';
import { NcrService } from '../ncr.service';
import { ToastrService } from 'ngx-toastr';
import { NonConformityReport } from '../non-conformity-report.model';
import { MasterFormOutput } from '../ncr-master-form/ncr-master-form.component';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

@Component({
  selector: 'app-ncr-edit',
  templateUrl: './ncr-edit.component.html',
  styleUrls: ['./ncr-edit.component.scss'],
})
export class NcrEditComponent implements OnInit {
  private _defaultRouteOption = 'opened';
  private id: string;
  private option: string;

  ncrToEdit: NonConformityReport;
  isLoadingScreen = true;
  loadingSubmit = false;
  warningStatus = false;
  issueFields = [];
  warehousesMap = {};
  formattedWarehouses = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogConfirmationService: DialogConfirmationService,
    private toastr: ToastrService,
    private authService: AuthService,
    private ncrService: NcrService,
    private warehouseService: WarehouseService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.option = params.get('option') || this._defaultRouteOption;
    });

    this.warehouseService.allWarehouses$.subscribe((warehouses) => {
      ({
        formattedWarehouses: this.formattedWarehouses,
        warehousesMap: this.warehousesMap,
      } = this.ncrService.buildWarehouseData(warehouses));
    });

    this.getNcrById();
  }

  isQualaWorker() {
    return !this.authService.hasDispatcherRole();
  }

  private showDialogConfirmation(
    question: string,
    icon: string,
    action: string
  ) {
    return this.dialogConfirmationService.openDialog({
      title: question,
      icon,
      action,
    });
  }

  private getNcrById() {
    this.isLoadingScreen = true;

    this.ncrService
      .getNCRById(this.id)
      .then((ncr) => {
        this.ncrToEdit = ncr;
        this.ncrToEdit;
        this.isLoadingScreen = false;
      })
      .catch(() => {
        this.toastr.error('', 'Cannot edit this item.');
        this.isLoadingScreen = false;
        this.router.navigate([`../../list/${this.option}`], {
          relativeTo: this.activatedRoute,
        });
      });
  }

  submitNcrChanges({ data, error }: MasterFormOutput) {
    if (!!error) {
      this.toastr.error('', 'Error updating NCR.');
      this.router.navigate([`../../list/${this.option}`], {
        relativeTo: this.activatedRoute,
      });
    }

    this.loadingSubmit = true;
    const ncrToSend: NonConformityReport = {
      id: data.id,
      customerMasterId: data.companyId || null,
      customer: {
        businessPartnerName: data.companyName,
        email: data.customerEmail,
        contactName: data.customerName,
        phone: data.phoneNumber,
        orderNumber: data.orderNumber,
      },
      problemReportedDateTime: {
        dateDiscovered: data.dateDiscovered,
        dateIssued: data.dateIssued,
      },
      qualaInformation: {
        terminalName: data.terminal,
        terminalNumber: data.terminalInfo.terminalNumber,
        workOrder: data.workOrder,
      },
      peopleInvolved: {
        cleaner: data.cleaner,
        facilityManagerName: data.facilityManager,
        shiftSupervisor: data.shiftSupervisor,
        technician: data.technician,
        namesOfPeopleInvolved:
          (data.namesOfPeopleInvolved &&
            data.namesOfPeopleInvolved.trim().split(',')) ||
          [],
        shiftStartTime: data.shiftStartTime,
      },
      tankInformation: {
        isFoodGrade: data.isFoodGradeClean,
        washType: data.typeOfWash,
        number: data.containerNumber,
        tankType: data.containerType,
      },
      dataFabricExecution: {
        issueFields: this.issueFields,
        warningStatus: this.warningStatus,
      },
      issueDescription: data.issueDescription,
      equipmentOwner: data.equipmentOwner,
      damage: {
        hasBeenDamaged: data.wasEquipmentDamaged,
        whatWasDamaged: data.whatWasDamaged,
        howItGotDamaged: data.howWasDamaged,
        repairCost: data.repairCost,
        driverName: data.driverName,
        whoDidTheRepair: data.whoDidTheRepair,
        receipt: data.receipt,
        tractorNumber: data.tractorNumber,
        trailerNumber: data.trailerNumber,
      },
      issueType: data.issueType,
      wasAnyoneInjured: data.wasAnyoneInjured,
      shipperName: data.shipperName,
      incidentCategory: data.review && data.review.incidentCategory,
      correctiveActionCategory:
        data.review && data.review.correctiveActionCategory,
      whoGotHurt: data.whoGotHurt,
      whoHadSignedStatement: data.peopleStatement,
      whoHaveBeenNotified: data.peopleNotified,
      hasTheOwnerBeenNotified: data.hasTheOwnerBeenNotified,
      originalInformation: data.originalInformation,
      investigation: data.investigation,
      fiveWhys: data.fiveWhys,
      remedialTraining: data.remedialTrainingProvided,
      uploadedFiles: data.uploadedFiles,
    };

    if (data.incidentResponsibility) {
      ncrToSend.incidentResponsibility = data.incidentResponsibility;
    } else {
      ncrToSend.incidentResponsibility =
        data.review && data.review.incidentResponsibility;
    }

    this.ncrService
      .updateNcr(ncrToSend, this.authService.user.username)
      .then(() => this.toastr.success('', 'NCR updated successfully.'))
      .catch(() => {
        this.toastr.error('', 'Error updating NCR.');
      })
      .finally(() => {
        this.loadingSubmit = false;
        this.router.navigate([`../../list/${this.option}`], {
          relativeTo: this.activatedRoute,
        });
      });
  }

  cancelEdition({ formTouched }: { formTouched: boolean }) {
    if (formTouched) {
      this.showDialogConfirmation(
        'Do you really want to discard the changes made to this item?',
        'check',
        'Yes'
      ).then((dialogResult) => {
        if (dialogResult) {
          this.router.navigate([`../../list/${this.option}`], {
            relativeTo: this.activatedRoute,
          });
        }
      });

      return;
    }

    this.router.navigate([`../../list/${this.option}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
