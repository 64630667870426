import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuOption } from '../menu/menu.model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private defaultHeaders: any;
  option: MenuOption;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.option = MenuOption.Reports;
  }

  getDashboardTerminalReports(): Promise<any> {
    const path = '/get-embed-url/terminal-weekly-report';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }

  getDashboardWashMetrics(): Promise<any> {
    const path = '/get-embed-url/customer-wash-metrics';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
  getDashboardEhsWasteManagement(): Promise<any> {
    const path = '/get-embed-url/EHS - Waste Management Terminal View';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
  getDashboardLeanDailyManagement(): Promise<any> {
    const path =
      '/get-embed-url/OPS - Lean Daily Management - Wash, IBC, Industrial & Rail Facilities';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }

  getDashboardByName(dashboardName): Promise<any> {
    const path = `/get-embed-url/${dashboardName}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
}
